import { useState } from 'react';

// Styles
import styles from '../index.module.sass';

export default function InputText({
  type = 'text',
  required = false,
  onChange,
  onBlur,
  onFocus,
  placeholder = '',
  value = '',
  name = '',
  id = '',
  requiredText = '* Required field',
  maxLength = '100',
  error = false,
  errorText = '',
  setError,
  pattern = '',
  readonly = false,
  disablePaste = false,
  autoComplete = '',
}) {
  const [requiredError, setRequiredError] = useState(false);

  function handleChange(e) {
    setRequiredError(false);
    if (onChange) onChange(e);
    if (setError) setError(false);
  }

  function handleBlur() {
    if (required) {
      if (value.trim() === '') setRequiredError(true);
      else setRequiredError(false);
    }
    if (onBlur) onBlur();
  }

  function handleFocus() {
    if (onFocus) onFocus();
  }

  return (
    <>
      <input
        type={type}
        placeholder={`${placeholder}`}
        className={`${styles.inputText} ${styles[requiredError || error]}`}
        id={id || null}
        onChange={(e) => handleChange(e)}
        name={name}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        maxLength={maxLength}
        pattern={pattern}
        readOnly={readonly}
        autoComplete={autoComplete}
        onPaste={(e) => {
          if (disablePaste) {
            e.preventDefault();
            return false;
          }
        }}
      />
      {requiredError && <div className={styles.errorText}>{requiredText}</div>}
      {error && <div className={styles.errorText}>{errorText}</div>}
    </>
  );
}
