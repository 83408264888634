import React, { useEffect, useCallback, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import styles from '../../index.module.sass';

export default function SendMediation({ mediationToSend, clearSession, setLoading, loading }) {
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState([]);
  const [sentFinished, setSentFinished] = useState(false);

  const sendM = useCallback(async () => {
    setLoading(true);
    setSentFinished(false);
    for (let i = 0; i < mediationToSend.length; i++) {
      await fetch(`${process.env.REACT_APP_WEBSERVICE}/SendPaymentsPayQuickerV1`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
        },
        body: JSON.stringify({
          idioma: '0',
          noKit: mediationToSend[i]?.NoKit,
          amount: mediationToSend[i]?.MontoPago,
          idPago: mediationToSend[i]?.IdTransaccion,
          comment: mediationToSend[i]?.Comentario,
        }),
      })
        .then((res) => res.json())
        .then(async (res) => {
          const { typeMessage, message } = res;

          console.log(res);
          if (typeMessage !== 'success') {
            setErrors((prevState) => {
              let requestErrors = prevState;
              requestErrors.push(message);
              return requestErrors;
            });
          }

          setProgress(((i + 1) * 100) / mediationToSend.length);
        })
        .catch((err) => {
          console.log('ERROR', err);
          setErrors((prevState) => {
            let requestErrors = prevState;
            requestErrors.push(
              `Error al intentar enviar mediación al kit ${mediationToSend[i]?.NoKit}`,
            );
            return requestErrors;
          });
          setProgress(((i + 1) * 100) / mediationToSend.length);
        });
    }
    setLoading(false);
    setSentFinished(true);
  }, [mediationToSend, setLoading]);

  useEffect(() => {
    sendM();
  }, [sendM]);

  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressContent}>
        <div className={styles.linearContainer}>
          <LinearProgress variant='determinate' value={progress} />
        </div>
        <div className={styles.percentage}>{Math.floor(progress) + '%'}</div>
      </div>
      {sentFinished && <div className={styles.sentFinished}>Transacciones enviadas</div>}
      {errors.length > 0 && (
        <div className={styles.errorsList}>
          {errors.map((val, key) => {
            return <div className={styles.errorText}>{val}</div>;
          })}
        </div>
      )}
    </div>
  );
}
