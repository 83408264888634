import { useState } from 'react';

// Components
import InputText from '../../InputText';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// Styles
import styles from '../../index.module.sass';
import { styled } from '@mui/material/styles';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#002747',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 350,
    backgroundColor: '#002747',
  },
});

export default function Password({ contractFields, handleChange, typecontract }) {
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function checkPassword() {
    const { password = '', confirmPassword = '' } = contractFields;
    if (password !== confirmPassword) setPasswordError(true);
    else setPasswordError(false);
  }

  return (
    <div className={`${styles.personal}`}>
      <div className={styles.areaName}>
        <i className={`material-icons-outlined ${styles.titleIcon}`}>password</i>
        Contraseña
      </div>
      <div className={styles.areaContainer}>
        <div className={`${styles.inputContainer} ${styles.width50}`}>
          <InputText
            type={`${(showPassword && 'text') || 'password'}`}
            placeholder='Contraseña *'
            className={styles.inputText}
            onChange={handleChange}
            name='password'
            maxLength='10'
            value={contractFields?.password || ''}
            requiredText='* Campo obligatorio'
            required
          />
          <div className={styles.showPassword}>
            <i
              className={`material-icons-outlined ${styles.showPasswordIcon}`}
              onClick={() => setShowPassword(!showPassword)}>
              {(showPassword === true && 'visibility_off') || 'visibility'}
            </i>
          </div>
        </div>
        <div className={`${styles.inputContainer} ${styles.width50}`}>
          <InputText
            type={`${(showPassword && 'text') || 'password'}`}
            placeholder='Confirmar contraseña'
            className={styles.inputText}
            onChange={handleChange}
            onBlur={checkPassword}
            name='confirmPassword'
            maxLength='10'
            value={contractFields?.confirmPassword || ''}
            requiredText='* Campo obligatorio'
            required
            error={passwordError}
            errorText='* Las contraseñas no coinciden'
            setError={setPasswordError}
            disablePaste={true}
          />
        </div>
        <div className={styles.instructions}>
          <div className={styles.instructionsTitle}>Instrucciones</div>
          <CustomWidthTooltip
            title={
              <div className={styles.instructionsAlert}>
                <span>Tu contraseña no debe contener:</span>
                <ul className={styles.instructionsList}>
                  <li>La palabra TE o TM</li>
                  <li>La letra Ñ</li>
                  <li>Acentos ni caracteres especiales</li>
                  <li>No debe ser igual a tú número de KIT</li>
                  <li>Menos de 5 caracteres</li>
                  <li>Maximum 10 characters</li>
                </ul>
              </div>
            }
            placement='right'
            arrow>
            {<span className={`material-icons-outlined ${styles.tooltip}`}>info</span>}
          </CustomWidthTooltip>
        </div>
      </div>
    </div>
  );
}
