import superagent from 'superagent';
import webservice from './webservice';
import getCookie from './getCookie';

export default async function postActualizarDomicilio({ jsonBody }) {
  let url = `${webservice}/AvalaraResolveAddress`;
  const domain =
    (window.location.hostname === 'localhost' && 'qa-cart.terramarbrandsusa.com') ||
    window.location.hostname;

  let response = {};

  await superagent
    .post(url)
    .send(jsonBody)
    .set('Content-Type', 'application/json')
    .set('dominio', domain)
    .auth(getCookie('TMBcartToken'), { type: 'bearer' })
    .then((res) => {
      response = res.body;
    })
    .catch((err) => {
      console.log(err);
      const resp = {
        ...jsonBody,
        error: err,
        tipoMensaje: 'error',
        mensaje: 'Error',
      };
      response = resp;
    });

  return response;
}
