import React, { useState, useEffect } from 'react';
import styles from './index.module.sass';

//Components
import Button from '@mui/material/Button';
import TableComponent from '../TableComponent';
import TableComponentPaqueteExpress from '../TableComponentPqExpress';
import CircularProgress from '@mui/material/CircularProgress';

//Dependencies
import superagent from 'superagent';

const PaqueteExpress = (props) => {
  const { device, setSectionName, setOptionSelected, webserviceURL2 } = props;
  const { screenSize = 'desktop' } = device;
  const [pdfURL, setPdfURL] = useState('');
  const [displayedOrders, setDisplayedOrders] = useState('');
  const [pdfRecord, setPdfRecord] = useState([]);
  const [guidesPending, setGuidesPending] = useState('');
  const [orders, setOrders] = useState([]);
  const [showOption, setShowOption] = useState('ORDERS');
  const [actionType, setActionType] = useState('CONSULTAR');
  const [loading, setLoading] = useState(false);

  const ordersHeader = [
    {
      title: 'Kit',
      field: 'kit',
    },
    {
      title: 'Pedido',
      field: 'pedido',
    },
    {
      title: 'Guía',
      field: 'guia',
    },
  ];

  const recordHeader = [
    {
      title: 'Link (Presione "ALT" mientras da click en el enlace)',
      isArray: true,
      isLink: true,
    },
  ];

  const getGuides = (action) => {
    setLoading(true);
    setActionType(action);
    setOrders([]);
    setPdfURL('');
    setPdfRecord([]);
    setGuidesPending('');
    setShowOption('ORDERS');

    if (action === 'GENERAR') {
      const option = window.confirm('¿Quieres generar las guías?');
      if (!option) {
        setLoading(false);
        return;
      }
    }

    const url = `${webserviceURL2}/generarGuiaPE?action=${action}`;
    superagent
      .get(url)
      .then((res) => {
        const { body = {} } = res;
        const {
          message = 'error',
          orderList = [],
          pathToPDF = '',
          pdfHistory = [],
          totalGuidesPending = '',
          typeMessage = 'error',
          guidesToGenerate = '',
        } = body;

        if (typeMessage !== 'success') {
          alert(message);
        }

        setDisplayedOrders(guidesToGenerate);
        setPdfURL(pathToPDF);
        setPdfRecord(pdfHistory.reverse());
        setGuidesPending(totalGuidesPending);
        setOrders(orderList);
        setLoading(false);
      })
      .catch((err) => {
        alert('ERROR EN GUIAS DE PAQUETE EXPRESS');
        console.log(err, 'ERROR EN GUIAS DE PAQUETE EXPRESS');
        setLoading(false);
      });
  };

  useEffect(() => {
    setSectionName('Paquete Express');
    setOptionSelected('Paquete Express');
  }, [setSectionName, setOptionSelected]);

  // function downloadItem(url, name) {
  //   superagent
  //     .get(url)
  //     .responseType('blob')
  //     .then((response) => {
  //       const blob = new Blob([response.body], { type: 'text/plain' });
  //       const link = document.createElement('a');
  //       link.href = URL.createObjectURL(blob);
  //       link.download = name;
  //       link.click();
  //       URL.revokeObjectURL(link.href);
  //     })
  //     .catch(console.error);
  // }

  return (
    <div id={styles.Estafeta} className={styles[screenSize]}>
      <div className={styles.title}>Consulta y generación de guías</div>
      <div className={styles.container}>
        <Button
          type='submit'
          variant='contained'
          size='large'
          color='primary'
          className={`${styles.button}`}
          onClick={(e) => getGuides('CONSULTAR')}>
          CONSULTAR
        </Button>
        <Button
          type='submit'
          variant='contained'
          size='large'
          color='primary'
          className={`${styles.button}`}
          onClick={(e) => getGuides('GENERAR')}>
          GENERAR
        </Button>
        {loading && <CircularProgress />}
        <div>
          {displayedOrders && (
            <div className={styles.text}>
              <span>Pedidos mostrados:</span> {displayedOrders}
            </div>
          )}
          {guidesPending && (
            <div className={styles.text}>
              <span>Guías pendientes:</span> {guidesPending}
            </div>
          )}
          {pdfURL && (
            <div className={styles.text}>
              <a href={pdfURL} className={styles.linkContainer}>
                Descargar txt con las guías generadas (Presione "ALT" mientras da click en este
                enlace)
              </a>
            </div>

            // <div
            //   className={styles.linkContainer}
            //   onClick={() => downloadItem(pdfURL, 'Descargar txt con las guías generadas')}>
            //   Descargar txt con las guías generadas
            // </div>
          )}
          {actionType === 'CONSULTAR' && (
            <div className={styles.linkContainer}>
              {orders.length > 0 && (
                <span className={styles.link} onClick={() => setShowOption('ORDERS')}>
                  Pedidos
                </span>
              )}
              {pdfRecord.length > 0 && (
                <span className={styles.link} onClick={() => setShowOption('RECORDS')}>
                  Historial de txt
                </span>
              )}
            </div>
          )}
          {orders.length > 0 && (showOption === 'ORDERS' || actionType === 'GENERAR') && (
            <TableComponent screenSize={screenSize} tableData={orders} tableItems={ordersHeader} />
          )}
          {pdfRecord.length > 0 && showOption === 'RECORDS' && actionType === 'CONSULTAR' && (
            <TableComponentPaqueteExpress
              screenSize={screenSize}
              tableData={pdfRecord}
              tableItems={recordHeader}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaqueteExpress;
