import React from 'react';
import { Route, Routes, NavLink } from 'react-router-dom';

import InviteList from './InviteList';
import ToInvite from './ToInvite';
import Mediation from './Mediation';
import MediationDetail from './Mediation/MediationDetail';
import MediationPeriod from './Mediation/MediationPeriod';

import styles from './index.module.sass';

export default function PayquickerV2({
  device,
  history,
  webserviceURL,
  getCookie,
  token,
  match,
  clearSession,
}) {
  return (
    <div>
      <div className={styles.secondaryMenu}>
        <NavLink to={`/admin/payquickerv2/`} className={styles.secondaryLink}>
          {({ isActive }) => <span className={isActive ? styles.active : ''}>Por invitar</span>}
        </NavLink>
        <NavLink to={`/admin/payquickerv2/mediation`} className={styles.secondaryLink}>
          {({ isActive }) => <span className={isActive ? styles.active : ''}>Mediación</span>}
        </NavLink>
      </div>
      <Routes>
        <Route
          exact
          path={`/invited`}
          element={
            <InviteList
              device={device}
              history={history}
              webserviceURL={webserviceURL}
              getCookie={getCookie}
              token={token}
              clearSession={clearSession}
            />
          }
        />
        <Route
          exact
          path={`/mediation/subtotals/:mediationYear/:mediationPeriod`}
          element={
            <MediationPeriod
              device={device}
              history={history}
              webserviceURL={webserviceURL}
              getCookie={getCookie}
              token={token}
              clearSession={clearSession}
            />
          }
        />
        <Route
          exact
          path={`/mediation/:mediationYear/:mediationPeriod/:tipoTransaccion`}
          element={
            <MediationDetail
              device={device}
              history={history}
              webserviceURL={webserviceURL}
              getCookie={getCookie}
              token={token}
              clearSession={clearSession}
            />
          }
        />
        <Route
          exact
          path={`/mediation`}
          element={
            <Mediation
              device={device}
              history={history}
              webserviceURL={webserviceURL}
              getCookie={getCookie}
              token={token}
              clearSession={clearSession}
            />
          }
        />
        <Route
          exact
          path={`/`}
          element={
            <ToInvite
              device={device}
              history={history}
              webserviceURL={webserviceURL}
              getCookie={getCookie}
              token={token}
              clearSession={clearSession}
            />
          }
        />
        <Route path={`/*`} element={<div>Por favor elige una opción</div>} />
      </Routes>
    </div>
  );
}
