import { useState, useEffect, useCallback } from 'react';
import styles from '../../index.module.sass';
import { useParams } from 'react-router-dom';

// Components
import CircularProgress from '@mui/material/CircularProgress';
import Personal from '../../Areas/Personal';
import Address from '../../Areas/Address';
import Contact from '../../Areas/Contact';
import Password from '../../Areas/Password';
import Button from '@mui/material/Button';
import InputText from '../../InputText';
import Alert from '../../../Alert';

// Queries
import postContratoUS from '../../../Queries/postContratoUS';
import postGlobal from '../../../Queries/postGlobal';
import getGlobal from '../../../Queries/getGlobal';
import AvalaraResolveAddress from '../../../Queries/AvalaraResolveAddress';

const country = process.env.REACT_APP_COUNTRY;

export default function Form({
  clearSession,
  setResponse,
  device,
  typecontract,
  history,
  getCookie,
}) {
  const { tokencontract = '' } = useParams();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [contractFields, setContractFields] = useState({
    pais: country,
    kitPatrocinadora: getCookie('usuarioOk'),
    sexo: '1',
  });
  const [fieldsErrors, setFieldsErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [sponsorName, setSponsorName] = useState('');
  const [sponsorLoading, setSponsorLoading] = useState(false);
  const [init, setInit] = useState(true);
  const [notVerifiedChecked, setNotVerifiedChecked] = useState(false);

  const { browser = '', screenSize = 'desktop' } = device || {};

  function handleChange(e) {
    let value = e.target.value.toUpperCase();
    const name = e.target.name;

    value = value.replace(/Ñ/g, 'N');
    value = value.replace(/ñ/g, 'N');

    if (name !== 'email' && name !== 'rfc') {
      value = value.replace(
        /[`~´!¡@#$%^&*|+=¿?;:'",<>œæ€®†¥øπå∫∂ƒ™¶§≤∑©√ßµ„…–¢∞¬÷“”≠‚ºª·ç¨Ωñ{}[\]]/gi,
        '',
      );
    }

    if (name === 'password' || name === 'confirmPassword') {
      value = value.replace(
        /[`~´!¡@#$%^&*()_|+\-=¿?;:'",.<>œæ€®†¥øπå∫∂ƒ™¶§≤∑©√ßµ„…–¢∞¬÷“”≠‚ºª·ç¨Ωñ{}[\]\\/ ]/gi,
        '',
      );
    }

    if (name === 'telefonoCasa' || name === 'celular') {
      value = value.replace(
        /[`~´!¡@#$%^&*()_|+\-=¿?;:'",.<>œæ€®†¥øπå∫∂ƒ™¶§≤∑©√ßµ„…–¢∞¬÷“”≠‚ºª·ç¨Ωñ{}abcdefghijklmnopqrstuvwxyz[\]\\/ ]/gi,
        '',
      );
    }

    if (name === 'kitPatrocinadora') {
      value = value.replace(
        /[`~´!¡@#$%^&*()_|+\-=¿?;:'",.<>œæ€®†¥øπå∫∂ƒ™¶§≤∑©√ßµ„…–¢∞¬÷“”≠‚ºª·ç¨Ωñ{}abcdefghijklmnopqrstuvwxyz[\]\\/ ]/gi,
        '',
      );
    }

    if (name === 'rfc') {
      const tmpValue = value.replace(/-/g, '');
      let newValue = '';
      for (let i = 0; i < tmpValue.length; i++) {
        if (i === 3 || i === 5) newValue = `${newValue}-${tmpValue[i]}`;
        else if (i < 9) newValue = `${newValue}${tmpValue[i]}`;
      }
      value = newValue;
    }

    if (name === 'email') {
      value = value.toLowerCase();
    }

    let tmpObj = {
      ...contractFields,
      [name]: value,
    };

    if (name === 'email') {
      if (value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        tmpObj.emailValidation = true;
      } else {
        tmpObj.emailValidation = false;
      }
    }

    if (
      name === 'calle' ||
      name === 'entreCalle' ||
      name === 'ciudad' ||
      name === 'estado' ||
      name === 'cp'
    ) {
      tmpObj.validado = false;
    }

    setContractFields(tmpObj);
  }

  const checkErrors = useCallback(() => {
    let fields = [];

    fields = [
      'nombre',
      'paterno',
      'birthday',
      'email',
      'sexo',
      // 'rfc',
      'calle',
      'ciudad',
      'estado',
      'cp',
      // 'telefonoCasa',
      'celular',
      'password',
      'confirmPassword',
      'kitPatrocinadora',
      'pais',
      'validado',
      'emailValidation',
    ];

    let errors = [];

    for (let i = 0; i < fields.length; i++) {
      if (!contractFields[fields[i]]) errors.push(fields[i]);
      else if (
        fields[i] !== 'privacyPolicy' &&
        fields[i] !== 'policiesProcedures' &&
        fields[i] !== 'consultantAgreement' &&
        fields[i] !== 'california' &&
        fields[i] !== 'validado' &&
        fields[i] !== 'emailValidation' &&
        contractFields[fields[i]] &&
        !contractFields[fields[i]].trim()
      )
        errors.push(fields[i]);
    }

    if (notVerifiedChecked) {
      const index = errors.indexOf('validado');
      if (index !== -1) {
        errors.splice(index, 1);
      }
    }

    if (!sponsorName || sponsorName === '** Patrocinadora no existe')
      errors.push('kitPatrocinadora');

    // var filteredArrayErrors = errors.filter(function (e) {
    //   if (notVerifiedChecked) {
    //     return e !== 'seven';
    //   } else {
    //     return e;
    //   }
    // });

    setFieldsErrors(errors);

    if (errors.length === 0 && contractFields.password === contractFields.confirmPassword)
      setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [contractFields, sponsorName, notVerifiedChecked]);

  async function handleSubmit() {
    if (fieldsErrors.length === 0 && !loading) {
      setLoading(true);
      const birthdayDate = contractFields.birthday.split('-');

      let resp;

      const idioma = window?.localStorage?.getItem('i18nextLng')?.includes('es') ? 0 : 1;

      if (typecontract === 'prefillus') {
        resp = await postGlobal({
          tmbservice: 'postPreContratoUS',
          jsonBody: {
            idioma,
            nombres: contractFields.nombre,
            apellido1: contractFields.paterno,
            fechaNacimiento: contractFields.birthday.replace(/-/g, ''),
            genero: contractFields.sexo,
            direccion1: contractFields.calle,
            direccion2: contractFields.entreCalle,
            ciudad: contractFields.ciudad,
            estado: contractFields.estado,
            CP: contractFields.cp,
            celular: contractFields.celular,
            email: contractFields.email,
            kitPatrocinador: contractFields.kitPatrocinadora,
            paisKitPatrocinador: contractFields.pais,
            nombreKitPatrocinador: sponsorName,
            omitirValidacionDom: notVerifiedChecked,
          },
        });

        const { /*tipoMensaje,*/ message, typeMessage } = resp;

        if (message === '498' || message === '499') {
          clearSession();
        }
        setLoading(false);

        if (typeMessage === 'error') {
          setAlertText(message);
          setOpen(true);
        } else if (typeMessage === 'success') {
          setAlertText(
            `se ha enviado un correo a ${contractFields.email} con las intrucciones para completar el registro.`,
          );
          setOpen(true);
          setContractFields({
            pais: country,
            kitPatrocinadora: getCookie('usuarioOk'),
          });
          window.scrollTo(0, 0);
        }
      } else {
        resp = await postContratoUS({
          jsonBody: {
            ...contractFields,
            accion: 'TERMINAR',
            usuarioOK: getCookie('usuarioOk'),
            materno: '',
            fechaNacAnio: birthdayDate[0] || '',
            fechaNacMes: birthdayDate[1] || '',
            fechaNacDia: birthdayDate[2] || '',
            acepto: 'S',
            colonia: contractFields?.estado || '',
            idioma,
            idPreCont: tokencontract,
            omitirValidacionDom: notVerifiedChecked,
          },
          getCookie,
        });

        const { tipoMensaje, mensajeValidacionDatos, mensaje } = resp;
        setLoading(false);

        if (tipoMensaje === 'error') {
          setAlertText(mensaje);
          setOpen(true);
        } else {
          if (mensaje === '498' || mensaje === '499') {
            clearSession();
          }

          setResponse(resp);

          if (
            mensajeValidacionDatos !== '*** Datos correctos ***' &&
            mensajeValidacionDatos !== ''
          ) {
            setAlertText(mensajeValidacionDatos);
            setOpen(true);
          }
        }
      }
    }
  }

  async function getPatroName(change, e) {
    setSponsorLoading(true);
    let pais = contractFields?.pais || 'US';
    const resp = await postContratoUS({
      jsonBody: {
        accion: 'CONTINUAR',
        kitPatrocinadora: contractFields?.kitPatrocinadora || '',
        pais: (change && e.target.value) || pais,
        idPreCont: tokencontract,
      },
    });

    const { /*tipoMensaje,*/ mensaje, nombrePatrocinadora = '' } = resp;

    setSponsorLoading(false);
    if (mensaje === '498' || mensaje === '499') {
      clearSession();
    }
    setSponsorName(nombrePatrocinadora);
  }

  const initPatroName = useCallback(
    async (propierties) => {
      const { initPatro = '' } = propierties || {};
      setSponsorLoading(true);
      const resp = await postContratoUS({
        jsonBody: {
          accion: 'CONTINUAR',
          kitPatrocinadora: initPatro || getCookie('usuarioOk') || '',
          pais: country,
        },
      });

      const { /*tipoMensaje,*/ mensaje, nombrePatrocinadora = '' } = resp;

      setSponsorLoading(false);
      if (mensaje === '498' || mensaje === '499') {
        clearSession();
      }
      setSponsorName(nombrePatrocinadora);
    },
    [clearSession, getCookie],
  );

  const getPreRegistration = useCallback(async () => {
    const resp = await getGlobal({
      tmbservice: 'getPreContratoUS',
      jsonBody: {
        idPreContratos: tokencontract,
        idioma: window?.localStorage?.getItem('i18nextLng')?.includes('es') ? 0 : 1,
      },
    });

    const { /*tipoMensaje,*/ message, typeMessage, messageCode } = resp;

    if (message === '498' || message === '499') {
      clearSession();
    }

    setLoading(false);

    if (typeMessage === 'error') {
      setAlertText(message);
      setOpen(true);
      if (messageCode === 403)
        setTimeout(() => {
          history.push('/');
        }, 3000);
    } else if (typeMessage === 'success') {
      const birth = `${resp.fechaNacimiento.substring(0, 4)}-${resp.fechaNacimiento.substring(
        4,
        6,
      )}-${resp.fechaNacimiento.substring(6)}`;

      const resolve = await AvalaraResolveAddress({
        jsonBody: {
          line1: resp.direccion1,
          line2: resp.direccion2,
          city: resp.ciudad,
          region: resp.estadoCodigo,
          postalCode: resp.CP,
          country: 'US',
        },
      });

      const { tipoMensaje = 'error', validatedAddresses = [] } = resolve;
      const { addressType = '' } = validatedAddresses[0] || {};

      let addressValidated = false;

      if (tipoMensaje === 'success' && addressType !== 'POBoxAddress') {
        addressValidated = true;
      }

      let emailValidation = false;

      if (resp.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        emailValidation = true;
      }

      setContractFields({
        nombre: resp.nombres,
        paterno: resp.apellido1,
        birthday: birth,
        sexo: resp.genero,
        calle: resp.direccion1,
        entreCalle: resp.direccion2,
        ciudad: resp.ciudad,
        estado: resp.estadoCodigo,
        cp: resp.CP,
        celular: resp.celular,
        email: resp.email,
        kitPatrocinadora: resp.kitPatrocinador,
        pais: resp.paisKitPatrocinador,
        validado: addressValidated,
        emailValidation,
      });

      initPatroName({ initPatro: resp.kitPatrocinador });
    }
  }, [tokencontract, initPatroName, history, clearSession]);

  useEffect(() => {
    checkErrors();
    if (init) {
      setInit(false);
      initPatroName();
    }
  }, [checkErrors, init, initPatroName]);

  useEffect(() => {
    if (tokencontract !== '') {
      getPreRegistration();
    }
  }, [tokencontract, getPreRegistration]);

  return (
    <div id={styles.Contracts} className={styles[screenSize]}>
      <Alert open={open} setOpen={setOpen} title='Atención' text={alertText} />
      <div className={styles.contractContainer}>
        <Personal
          contractFields={contractFields}
          handleChange={handleChange}
          typecontract={typecontract}
        />
        <Address
          contractFields={contractFields}
          handleChange={handleChange}
          setContractFields={setContractFields}
          typecontract={typecontract}
          tokencontract={tokencontract}
          notVerifiedChecked={notVerifiedChecked}
          setNotVerifiedChecked={setNotVerifiedChecked}
        />
        <Contact
          contractFields={contractFields}
          handleChange={handleChange}
          typecontract={typecontract}
          device={device}
        />
        {typecontract !== 'prefillus' && (
          <Password
            contractFields={contractFields}
            handleChange={handleChange}
            typecontract={typecontract}
            device={device}
          />
        )}

        <div className={styles.finalInputs}>
          <div className={styles.areaName}>
            <i className={`material-icons-outlined ${styles.titleIcon}`}>auto_awesome</i>
            Patrocinadora
          </div>
          <div className={styles.areaContainer}>
            <div className={`${styles.inputContainer} ${styles.width50} ${styles.patroKit}`}>
              <InputText
                type='text'
                placeholder='Patrocinadora*'
                onChange={handleChange}
                name='kitPatrocinadora'
                maxLength='34'
                value={contractFields?.kitPatrocinadora || ''}
                requiredText='* Campo obligatorio'
                onBlur={getPatroName}
                required
                readonly={sponsorLoading}
              />
            </div>

            <div className={`${styles.inputContainer} ${styles.width50}`}>
              <select
                disabled={sponsorLoading}
                className={styles.inputText}
                onChange={(e) => {
                  handleChange(e);
                  getPatroName(true, e);
                }}
                name='pais'
                maxLength='34'
                autoComplete={(browser === 'Chrome' && 'nope') || 'off'}
                value={contractFields?.pais || ''}>
                {/* <option value=''>-- {t('sponsorcountry')} --*</option> */}
                <option value='MX'>Patrocinadora de México</option>
                <option value='US'>Patrocinadora de EE. UU.</option>
              </select>
            </div>
            <div className={styles.sponsorName}>
              {(sponsorLoading && <CircularProgress className={styles.sponsorProgress} />) ||
                sponsorName}
            </div>
          </div>
        </div>

        <div className={styles.actionContainer}>
          <div className={styles.buttonContainer}>
            <Button
              type='submit'
              variant='contained'
              size='large'
              color='primary'
              className={`${styles.button} ${styles[buttonDisabled]}`}
              onClick={handleSubmit}
              disabled={sponsorLoading || buttonDisabled}>
              {(loading && <CircularProgress className={styles.circularProgress} />) || (
                <span className={styles.buttonText}>Continuar</span>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
