import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './index.module.sass';

export default function Payquicker() {
  const [fileType, setFileType] = useState('1');
  const [base64File, setBase64File] = useState('');
  const [base64XSL, setBase64XSL] = useState('');
  const [base64TXT, setBase64TXT] = useState('');
  const [loading, setLoading] = useState(false);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getFile = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    const slpit64 = base64.split(',');

    if (slpit64.length > 1) {
      setBase64File(slpit64[1]);
    }
  };

  function sendPayquicker() {
    if (!fileType || !base64File) {
      alert('Porfavor llena los cambios obligatorios');
      return;
    }

    setBase64XSL('');
    setBase64TXT('');

    setLoading(true);

    const webserviceType =
      fileType === '1' ? 'PostInvitacionPayQuickerTxt' : 'PostPagosPayQuickerTxt';

    fetch(`${process.env.REACT_APP_WEBSERVICE}/${webserviceType}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
      },
      body: JSON.stringify({
        base64encoded: base64File,
        idioma: 0,
      }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const { typeMessage = 'error', base64excel = '', base64txt = '', message } = res;

        if (typeMessage === 'success') {
          setBase64XSL(base64excel);
          setBase64TXT(base64txt);
        } else {
          alert(message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log('ERROR', err);
        setLoading(false);
      });
  }

  function saveBase64AsFile({ mediaType, fileName, base64String }) {
    var a = document.createElement('a');
    a.href = mediaType + base64String;
    a.download = fileName;
    a.textContent = 'Download file!';
    document.body.appendChild(a);
    a.click();
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Payquicker</div>
      <div className={styles.subtitle}>
        Selecciona un archivo Excel para poder convertirlo en un archivo TXT que te ayudará a subir
        de forma masiva las invitaciones o pagos en Payquicker.
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputTitle}>Tipo de archivo *</div>
        <select
          value={fileType}
          className={styles.input}
          onChange={(e) => {
            setFileType(e.target.value);
            setBase64XSL('');
            setBase64TXT('');
          }}>
          <option value='1'>Invitaciones</option>
          <option value='2'>Pagos</option>
        </select>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputTitle}>Selecciona un archivo</div>
        <input
          type='file'
          onChange={(e) => {
            setBase64XSL('');
            setBase64TXT('');
            getFile(e);
          }}
          className={styles.inputfile}
        />
      </div>
      <div className={styles.responseButtons}>
        {base64XSL && (
          <div>
            <button
              className={styles.downloadButton}
              onClick={() => {
                const date = new Date();
                saveBase64AsFile({
                  mediaType:
                    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
                  fileName: `payquicker-${date.getFullYear()}${
                    date.getMonth() + 1
                  }${date.getDate()}-${date.getTime()}.xlsx`,
                  base64String: base64XSL,
                });
              }}>
              Descargar Excel
            </button>
          </div>
        )}
        {base64TXT && (
          <div>
            <button
              className={`${styles.downloadButton} ${styles.downloadTXT}`}
              onClick={() => {
                const date = new Date();
                saveBase64AsFile({
                  mediaType: 'data:text/plain;base64,',
                  fileName: `payquicker-${date.getFullYear()}${
                    date.getMonth() + 1
                  }${date.getDate()}-${date.getTime()}.txt`,
                  base64String: base64TXT,
                });
              }}>
              Descargar TXT
            </button>
          </div>
        )}
      </div>

      <div
        className={styles.button}
        onClick={() => {
          if (loading) return;
          sendPayquicker();
        }}>
        {(loading && <CircularProgress className={styles.circularProgress} />) || 'Enviar'}
      </div>
    </div>
  );
}
