import superagent from 'superagent';
import getCookie from './getCookie';

export default async function postContratoUS({ jsonBody }) {
  const url = `${process.env.REACT_APP_WEBSERVICE_US}/postContratoUS`;
  let response = {};

  jsonBody.yCalle = jsonBody.yLaCalle || '';

  await superagent
    .post(url)
    .set('Content-Type', 'application/json')
    .send(jsonBody)
    .auth(getCookie('TMBcartToken'), { type: 'bearer' })
    .then((res) => {
      if (res && res.body) response = res.body;
    })
    .catch((err) => {
      console.log(err);
      const resp = {
        ...jsonBody,
        error: err,
        tipoMensaje: 'error',
        mensaje: 'Error',
      };
      response = resp;
    });

  return response;
}
