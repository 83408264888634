import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import styles from '../index.module.sass';

export default function PostGifts({
  device,
  fechaInicioInit = '',
  fechaFinInit = '',
  claveProductoInit = '',
  descripcionRegaloInit = '',
  tipoRegaloInit = 1,
  idInit = '',
  statusInit = 'V',
  showStatus = false,
  callback,
}) {
  const { screenSize = 'desktop' } = device || {};
  const [loading, setLoading] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(fechaInicioInit);
  const [fechaFin, setFechaFin] = useState(fechaFinInit);
  const [claveProducto, setClaveProducto] = useState(claveProductoInit);
  const [descripcionRegalo, setDescripcionRegalo] = useState(descripcionRegaloInit);
  const [estatus, setEstatus] = useState(statusInit);
  const [tipoRegalo, setTipoRegalo] = useState(tipoRegaloInit);

  function saveGift() {
    if (!fechaInicio || !fechaFin || !claveProducto || !descripcionRegalo || !tipoRegalo) {
      alert('Todos los campos son obligatorios');
      return;
    }
    setLoading(true);
    let tmpInicio = fechaInicio.replace(/-/g, '').replace(/:/g, '');
    tmpInicio = tmpInicio.split('T');

    let tmpFin = fechaFin.replace(/-/g, '').replace(/:/g, '');
    tmpFin = tmpFin.split('T');

    const webservice = idInit ? 'postActPromocion' : 'postAgregarRegalo';

    fetch(`${process.env.REACT_APP_WEBSERVICE}/${webservice}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
      },
      body: JSON.stringify({
        id: idInit,
        idPromocion: tipoRegalo,
        descripcion: descripcionRegalo,
        fechaInicio: tmpInicio[0],
        horaInicio: `${tmpInicio[1]}00`,
        fechaFin: tmpFin[0],
        horaFin: `${tmpFin[1]}00`,
        codigoProducto: claveProducto,
        idioma: '0',
        estatus: estatus,
      }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const { message = '', typeMessage = 'error' } = res;

        if (typeMessage === 'error') {
          alert(message);
        } else {
          alert('El regalo ha sido publicado');
          if (!idInit) {
            setFechaInicio('');
            setFechaFin('');
            setClaveProducto('');
            setDescripcionRegalo('');
            setTipoRegalo(1);
          }
        }
        setLoading(false);
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        console.log('ERROR', err);
        alert('Ocurrió un error, intentalo de nuevo');
        setLoading(false);
      });
  }

  return (
    <div className={`${styles.subContainer} ${styles[screenSize]}`}>
      <div className={styles.row}>
        <div className={styles.inputContainer}>
          <div className={styles.inputTitle}>Fecha de inicio *</div>
          <input
            className={styles.input}
            type='datetime-local'
            onChange={(e) => {
              setFechaInicio(e.target.value);
            }}
            value={fechaInicio}
          />
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputTitle}>Fecha de fin *</div>
          <input
            className={styles.input}
            type='datetime-local'
            onChange={(e) => {
              setFechaFin(e.target.value);
            }}
            value={fechaFin}
          />
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputTitle}>Clave de producto *</div>
          <input
            className={styles.input}
            type='text'
            onChange={(e) => {
              setClaveProducto(e.target.value);
            }}
            maxLength={5}
            placeholder='Ej. 55555'
            value={claveProducto}
          />
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputTitle}>Tipo de regalo *</div>
          <select
            value={tipoRegalo}
            className={styles.input}
            onChange={(e) => {
              setTipoRegalo(e.target.value);
            }}>
            <option value={1}>Aplica para primer pedido</option>
            <option value={2}>Aplica para segundo pedido</option>
          </select>
        </div>

        {showStatus && (
          <div className={styles.inputContainer}>
            <div className={styles.inputTitle}>Estatus</div>
            <select
              value={estatus}
              className={styles.input}
              onChange={(e) => {
                setEstatus(e.target.value);
              }}>
              <option value='V'>Vigente</option>
              <option value='C'>Cancelado</option>
            </select>
          </div>
        )}
      </div>

      <div className={styles.row}>
        <div className={styles.inputContainer}>
          <div className={styles.inputTitle}>Descripción *</div>
          <input
            className={styles.input}
            type='text'
            onChange={(e) => {
              setDescripcionRegalo(e.target.value);
            }}
            maxLength={100}
            placeholder='Ej. Regalo por continuidad'
            value={descripcionRegalo}
          />
        </div>
      </div>

      <div
        className={styles.button}
        onClick={() => {
          if (loading) return;
          saveGift();
        }}>
        {(loading && <CircularProgress className={styles.circularProgress} />) || 'Guardar'}
      </div>
    </div>
  );
}
