import React, { useState } from 'react';

import styles from './index.module.sass'

import ToInviteList from './ToInviteList';
import InvitedResult from './InvitedResult';

export default function ToInvite() {
  const [viewToSow, setViewToShow] = useState('ToInviteList');
  const [invitedResult, setInvitedResult] = useState([]);

    return <div className={styles.container}>
        {viewToSow === 'ToInviteList' && <ToInviteList setViewToShow={setViewToShow} setInvitedResult={setInvitedResult}/>}
        {viewToSow === 'InvitedResult' && <InvitedResult setViewToShow={setViewToShow} invitedResult={invitedResult} />}

    </div>
}