import React, { useEffect, useState, useCallback } from 'react';
import styles from './index.module.sass';

//Dependencies
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import superagent from 'superagent';
import TextField from '@mui/material/TextField';

//Components
import Catalog from './Catalog';
import Discounts from './Discounts';

//Constants
import months from './months.js';

const date = new Date();
const year = date.getFullYear();
const month = ('0' + (date.getMonth() + 1)).slice(-2);

const Loadsheet = (props) => {
  const { setSectionName, setOptionSelected, match, webserviceURL, getCookie, token } = props;

  const [catalog, setCatalog] = useState([]);
  const [categories, setCategories] = useState([]);
  const [listCat, setListCat] = useState({});
  const [loading, setLoading] = useState(false);
  const [catSelected, setCatSelected] = useState({ yearSelected: '', monthSelected: '' });
  const [catExist, setCatExist] = useState(false);
  const [showView, setShowView] = useState('catalog');
  const [keyPromo, setKeyPromo] = useState('');
  const [dicountPromo, setDicountPromo] = useState('');

  //A = para modificar
  //B = para desactivar
  //N = para nuevos registros

  const getCategories = useCallback(() => {
    setLoading(true);
    const url = `${webserviceURL}/getCategorlsg`;
    superagent
      .get(url)
      .auth(token, (process.env.REACT_APP_COUNTRY === 'MX' && {}) || { type: 'bearer' })
      .then((res) => {
        const { body = {} } = res;
        const { category = [], getCatvresult = {} } = body;
        const { typeMessage = 'error', message = '' } = getCatvresult;
        if (typeMessage !== 'error') {
          setCategories(category);
        } else {
          alert(message);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert('ERROR EN LOADSHEET GETCATEGORIAS');
        console.log(err, 'ERROR EN LOADSHEET GETCATEGORIAS');
        setLoading(false);
      });
  }, [webserviceURL, token]);

  const getListCat = useCallback(
    (type) => {
      const yearSelected = getCookie('yearSelected') || year + '';
      let monthSelected = getCookie('monthSelected') || month;

      if (type === 'new') monthSelected = ('0' + (parseInt(month) + 1)).slice(-2);

      document.cookie = `yearSelected=${yearSelected}; path=/`;
      document.cookie = `monthSelected=${monthSelected}; path=/`;

      const url = `${webserviceURL}/getListCatg`;
      superagent
        .get(url)
        .auth(token, (process.env.REACT_APP_COUNTRY === 'MX' && {}) || { type: 'bearer' })
        .then((res) => {
          const { body = {} } = res;

          const { typeMessage = 'error', message = '', getCatvresult = [] } = body;
          if (typeMessage !== 'error') {
            //let tmpObjct = Object.assign(listCat, {});
            let tmpObjct = {};
            getCatvresult.map((val) => {
              const catYear = val.catalog.substr(0, 4);
              const catMonth = val.catalog.substr(4);
              if (!tmpObjct[catYear]) {
                tmpObjct[catYear] = [];
              }
              tmpObjct[catYear].push(catMonth);
              if (
                tmpObjct[year] &&
                tmpObjct[year].includes(('0' + (parseInt(month) + 1)).slice(-2))
              ) {
                setCatExist(true);
              }
              return null;
            });
            setListCat(tmpObjct);
            setCatSelected({ yearSelected, monthSelected });
          } else {
            alert(message);
            setListCat({});
          }
        })
        .catch((err) => {
          console.log(err);
          setListCat([]);
          alert('An error occurred');
        });
    },
    [webserviceURL, getCookie, token],
  );

  const getCatalog = useCallback(
    (type) => {
      setCatalog([]);
      setLoading(true);

      let yearSelected = getCookie('yearSelected') || year;
      let monthSelected = getCookie('monthSelected') || month;

      if (type === 'new') {
        monthSelected = ('0' + (parseInt(month) + 1)).slice(-2);
        if (monthSelected === '13') {
          monthSelected = '01';
          yearSelected = year + 1;
        }
      }

      const url = `${webserviceURL}/getCatalog?yearMonth=${yearSelected}${monthSelected}`;

      superagent
        .get(url)
        .auth(token, (process.env.REACT_APP_COUNTRY === 'MX' && {}) || { type: 'bearer' })
        .then((res) => {
          const { body = {} } = res;
          const { typeMessage = 'error', message = '', getCatvresult = [] } = body;
          if (typeMessage !== 'error') {
            setCatalog(getCatvresult);
            getCategories();
            if (type === 'new') getListCat('new');
          } else {
            alert(message);
            setLoading(false);
          }
        })
        .catch((err) => {
          alert('ERROR EN LOADSHEET GETCATALOG');
          console.log(err, 'ERROR EN LOADSHEET GETCATALOG');
          setLoading(false);
        });
    },
    [getCategories, getListCat, webserviceURL, getCookie, token],
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const yearSelected = (name === 'yearSelected' && value) || catSelected.yearSelected;
    const monthSelected = (name === 'yearSelected' && listCat[value][0]) || value;

    setCatSelected({
      yearSelected,
      monthSelected,
    });

    document.cookie = `yearSelected=${yearSelected}; path=/`;
    document.cookie = `monthSelected=${monthSelected}; path=/`;

    getCatalog();
  };

  const createNew = () => {
    if (catExist) {
      alert('the catalog for the following month already exists');
      return null;
    }

    let question = window.confirm('¿Quieres crear el catálogo del siguiente mes?');
    if (question) {
      getCatalog('new');
    }
  };

  const putProductInPromotions = () => {
    if (keyPromo.trim() === '' || dicountPromo.trim() === '') {
      alert('both fields are required');
      return;
    }

    const isZero = parseFloat(dicountPromo) <= 0;

    const jsonBody = {
      description: '',
      additDescrip: '',
      productLetter: '',
      baseSubcateg: '',
      promotSubcat: '',
      charactoption: '',
      optInvDown: '',
      listPrice: '',
      promotPrice: '',
      isSet: '',
      category: '',
      product: keyPromo,
      discount: (parseFloat(dicountPromo) < 0 && '0') || dicountPromo,
      status: (isZero && 'E') || 'D', // D = se pasa a promociones, E = se quita de promociones
      yearMonth: `${getCookie('yearSelected')}${getCookie('monthSelected')}`,
    };

    const url = `${webserviceURL}/postCatalog`;

    superagent
      .post(url)
      .set('Content-Type', 'application/json')
      .auth(token, (process.env.REACT_APP_COUNTRY === 'MX' && {}) || { type: 'bearer' })
      .send(jsonBody)
      .then((res) => {
        if (res.body) {
          const { body = {} } = res;
          const { message = '' } = body;
          window.scrollTo(0, 0);
          getCatalog();
          alert(message);
          setKeyPromo('');
          setDicountPromo('');
        } else {
          alert('An error occurred, please try again');
        }
      })
      .catch((err) => {
        console.log(err);
        alert('An error occurred');
      });
  };

  useEffect(() => {
    setSectionName('Loadsheet');
    setOptionSelected('loadsheet');
    getCatalog();
    getListCat();
  }, [getCatalog, getListCat, setOptionSelected, setSectionName]);

  return (
    <div id={styles.Loadsheet} className={styles.productListContainer}>
      <div className={styles.catList}>
        <div className={styles.title}>Catálogo</div>
        <div className={styles.inputsContainer}>
          <div className={`${styles.four} ${styles.first}`}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>Año</InputLabel>
              <Select
                label='Año'
                value={catSelected.yearSelected}
                onChange={handleChange}
                name='yearSelected'>
                {Object.keys(listCat).map((val, key) => {
                  return (
                    <MenuItem key={key} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className={`${styles.four} `}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>Mes</InputLabel>
              <Select
                label='Mes'
                value={catSelected.monthSelected}
                onChange={handleChange}
                name='monthSelected'>
                {Object.keys(listCat).map((val, key) => {
                  let tmpObjct = null;
                  if (catSelected.yearSelected === val && listCat[val].length > 0) {
                    tmpObjct = listCat[val].map((subval, subkey) => {
                      return (
                        <MenuItem key={subkey} value={subval}>
                          {months[parseInt(subval) - 1]}
                        </MenuItem>
                      );
                    });
                  }
                  return tmpObjct;
                })}
              </Select>
            </FormControl>
          </div>
          <div className={styles.newCatalogButton}>
            <Button
              type='button'
              variant='contained'
              size='large'
              color='primary'
              onClick={() => createNew()}
              className={`${styles.button}`}>
              Nuevo catálogo
            </Button>
          </div>
        </div>

        <div className={styles.promoCreator}>
          <div className={styles.title}>Producto en promoción</div>
          <div className={styles.inputsContainer}>
            <div className={`${styles.four} ${styles.first}`}>
              <TextField
                onChange={(e) => setKeyPromo(e.target.value)}
                name='clave'
                label='Clave'
                type='text'
                value={keyPromo}
                inputProps={{ maxLength: 15 }}
                variant='outlined'
                fullWidth
              />
            </div>
            <div className={`${styles.four} `}>
              <TextField
                onChange={(e) => setDicountPromo(e.target.value)}
                name='percentage'
                label='% descuento (Ej. 0.15)'
                type='number'
                value={dicountPromo}
                inputProps={{ maxLength: 5 }}
                variant='outlined'
                fullWidth
              />
            </div>
            <div className={styles.newCatalogButton}>
              <Button
                type='button'
                variant='contained'
                size='large'
                color='primary'
                onClick={() => putProductInPromotions()}
                className={`${styles.button}`}>
                Agregar
              </Button>
            </div>
          </div>
        </div>

        <div className={styles.selectList}>
          <div
            className={`${styles.optionList} ${styles[showView === 'catalog']}`}
            onClick={() => setShowView('catalog')}>
            Catálogo
          </div>
          <div
            className={`${styles.optionList} ${styles[showView === 'discount']}`}
            onClick={() => setShowView('discount')}>
            Descuentos
          </div>
        </div>
      </div>

      {showView === 'catalog' && (
        <Catalog
          getCatalog={getCatalog}
          catExist={catExist}
          getCookie={getCookie}
          year={year}
          month={month}
          match={match}
          loading={loading}
          catalog={catalog}
          categories={categories}
          setLoading={setLoading}
          webserviceURL={webserviceURL}
        />
      )}

      {showView === 'discount' && (
        <Discounts
          webserviceURL={webserviceURL}
          setLoading={setLoading}
          match={match}
          getCookie={getCookie}
          year={year}
          month={month}
          catSelected={catSelected}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Loadsheet;
