import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';

import PostGifts from './PostGifts';
import GetGifts from './GetGifts';

import styles from '../index.module.sass';

export default function Gifts({ device, history, webserviceURL, getCookie, token, match }) {
  return (
    <div className={styles.container}>
      <div className={styles.secondaryMenu}>
        <Link to={`/admin/promos/regalos`} className={styles.secondaryLink}>
          Crear regalo
        </Link>
        <Link to={`/admin/promos/regalos/lista`} className={styles.secondaryLink}>
          Regalos publicados
        </Link>
      </div>
      <Routes>
        <Route
          path={`lista/*`}
          element={
              <GetGifts
                device={device}
                history={history}
                webserviceURL={webserviceURL}
                getCookie={getCookie}
                token={token}
              />}
        />
        <Route
          path={`/*`}
          element={
              <PostGifts
                device={device}
                history={history}
                webserviceURL={webserviceURL}
                getCookie={getCookie}
                token={token}
              />}
        />
      </Routes>
    </div>
  );
}
