import { useState, useEffect } from 'react';

// Components
import InputText from '../../InputText';

// Styles
import styles from '../../index.module.sass';

export default function Personal({ contractFields, handleChange, typecontract }) {
  const [birthdayFocused, setBirthdayFocused] = useState(false);

  function handleBirthdayFocus() {
    document.getElementById('birthday').focus();
    setBirthdayFocused(true);
  }

  useEffect(() => {
    if (contractFields?.birthday) setBirthdayFocused(true);
  }, [contractFields]);

  return (
    <div className={`${styles.personal}`}>
      <div className={styles.areaName}>
        <i className={`material-icons-outlined ${styles.titleIcon}`}>portrait</i>
        Datos personales
      </div>
      <div className={styles.areaContainer}>
        <div className={`${styles.inputContainer} ${styles.width50}`}>
          <InputText
            onChange={handleChange}
            value={contractFields?.nombre || ''}
            requiredText='* Campo obligatorio'
            type='text'
            placeholder='Nombre *'
            name='nombre'
            maxLength='44'
            required
          />
        </div>
        <div className={`${styles.inputContainer} ${styles.width50}`}>
          <InputText
            type='text'
            placeholder='Apellido *'
            className={styles.inputText}
            onChange={handleChange}
            name='paterno'
            maxLength='39'
            value={contractFields?.paterno || ''}
            requiredText='* Campo obligatorio'
            required
          />
        </div>

        <div className={`${styles.inputContainer} ${styles.width50}`}>
          <InputText
            type='date'
            placeholder='Fecha de nacimiento *'
            className={styles.inputText}
            onChange={(e) => handleChange(e)}
            name='birthday'
            id='birthday'
            maxLength='34'
            value={contractFields?.birthday || ''}
            requiredText='* Campo obligatorio'
            onFocus={() => setBirthdayFocused(true)}
            onBlur={() => {
              if (!contractFields?.birthday) setBirthdayFocused(false);
            }}
            required
          />
          {!birthdayFocused && (
            <div className={styles.floatingLabel} onClick={() => handleBirthdayFocus()}>
              Fecha de nacimiento *
            </div>
          )}
        </div>

        <div className={`${styles.inputContainer} ${styles.width50}`}>
          <select
            className={styles.inputText}
            onChange={(e) => handleChange(e)}
            name='sexo'
            maxLength='34'
            value={contractFields?.sexo || ''}>
            <option value=''>-- Seleciona tu genero -- *</option>
            <option value='1'>Femenino</option>
          </select>
        </div>

        {typecontract !== 'prefillus' && (
          <div className={`${styles.inputContainer} ${styles.width50}`}>
            <InputText
              type='text'
              placeholder='SSN / ITIN'
              className={styles.inputText}
              onChange={handleChange}
              name='rfc'
              maxLength='11'
              value={contractFields?.rfc || ''}
            />
          </div>
        )}
      </div>
    </div>
  );
}
