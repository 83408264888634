import { useEffect, useState } from 'react';

// Styles
import styles from './index.module.sass';

export default function OrderCard({
  children,
  title,
  open = true,
  icon = '',
  iconColor = '',
  iconText = '',
  iconSize = 24,
  callback,
  customStyles = {},
}) {
  const [isOpen, setIsOpen] = useState(open);

  function handleClick() {
    if (callback) callback(!isOpen);
    else setIsOpen(!isOpen);
  }

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  // <div className={styles.editaddress}>
  //   <i className={`material-icons ${styles.sectionicon}`}>edit</i>
  //   <span>Editar</span>
  // </div>;
  return (
    <div id={styles.OrderCard} className={customStyles}>
      {title && (
        <div className={styles.header}>
          <div className={styles.cardTitle}>{title}</div>
          <div>
            <div className={styles.iconContainer} onClick={handleClick}>
              <i
                className={`material-icons ${styles.icon} ${styles[iconColor]}`}
                style={{ fontSize: iconSize }}>
                {(icon && icon) || (isOpen && 'expand_less') || 'expand_more'}
              </i>
              {iconText && <span className={`${styles[iconColor]}`}>{iconText}</span>}
            </div>
          </div>
        </div>
      )}
      {isOpen && children}
    </div>
  );
}
