import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import styles from '../../index.module.sass';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Row({ row }) {
  return (
    <React.Fragment>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={styles.rowStyle}>
        <TableCell>
          <NavLink
            to={`/admin/payquickerv2/mediation/${row?.axo}/${row?.periodo}/${row?.tipoTransaccion}`}
            className={styles.tableLink}>
            <span>Ver detalle</span>
          </NavLink>
        </TableCell>
        <TableCell>{row.axo}</TableCell>
        <TableCell>{row.periodo}</TableCell>
        <TableCell>{row.descripcion}</TableCell>
        <TableCell>{row.montoTotal}</TableCell>
        <TableCell>{row.noLideres}</TableCell>
        <TableCell>{row.noLideresTrAplicadas}</TableCell>
        <TableCell>{row.noTransaccionesAplicadas}</TableCell>
        <TableCell>{row.noTransaccionesGen}</TableCell>
        <TableCell>{row.porcentajeAvance}</TableCell>
        <TableCell>
          {row.estatus === 'GEN' ? 'Generado' : row.estatus === 'FIN' ? 'Finalizado' : 'En proceso'}
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function MediationPeriod({ clearSession }) {
  const { mediationYear, mediationPeriod } = useParams();
  const [paymentsList, setPaymentsList] = useState([]);

  const geSubtotals = useCallback(async () => {
    await fetch(
      `${process.env.REACT_APP_WEBSERVICE}/getSubTotalesMediacion?axo=${mediationYear}&periodo=${mediationPeriod}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
        },
      },
    )
      .then((res) => res.json())
      .then(async (res) => {
        const { typeMessage = 'error', messageCode, message, DataSubTotalesMediacion = [] } = res;
        if (typeMessage === 'success' && messageCode === 200) {
          setPaymentsList(DataSubTotalesMediacion);
        } else {
          if (message === '498' || message === '499') {
            alert('Tu sesión a caducado');
            clearSession();
          }
        }
      })
      .catch((err) => {
        console.log('ERROR', err);
      });
  }, [mediationYear, mediationPeriod, clearSession]);

  useEffect(() => {
    geSubtotals();
  }, [geSubtotals]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Año</TableCell>
              <TableCell>Periodo</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>No. Líderes</TableCell>
              <TableCell>Líderes con tx aplicadas</TableCell>
              <TableCell>Tx aplicadas</TableCell>
              <TableCell>Tx Generadas</TableCell>
              <TableCell>Porcentaje</TableCell>
              <TableCell>Estatus</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentsList.map((row) => (
              <Row key={row.descripcion} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
