import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import superagent from 'superagent';

import Alert from '../Alert';

import styles from './index.module.sass';

import LOGO from '../../images/sign/logo.png';

export default function AcceptOrder() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [form, setForm] = useState({
    kit: '',
    password: '',
  });

  const handleChange = useCallback((e, type) => {
    setForm((prevValue) => {
      return {
        ...prevValue,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      await superagent
        .post(`${process.env.REACT_APP_WEBSERVICE}/postAutorizaOrdenCompra`)
        .set('Content-Type', 'application/json')
        .set('seccion', 'ATENCIONES')
        .send({
          folio: orderId,
          usuario: form.kit,
          contrasena: form.password,
        })
        .then((res) => {
          console.log(res.body);
          const { message, typeMessage, messageCode } = res?.body ?? {};

          if (typeMessage !== 'success') {
            setAlertText(message);
            setAlertTitle('Error');
            setAlertOpen(true);
          } else {
            setAlertText('La orden fue aceptada');
            setAlertTitle('Éxito');
            setAlertOpen(true);
          }

          if (messageCode !== 401) {
            setTimeout(() => {
              navigate('/purchaseorders/finishpage');
            }, 10000);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      setLoading(false);
    },
    [form, orderId, navigate],
  );

  return (
    <div className={styles.container}>
      <Alert title={alertTitle} text={alertText} open={alertOpen} setOpen={setAlertOpen} />
      <div>
        <div className={styles.logoContainer}>
          <img src={LOGO} alt='Terramar logo' className={styles.logo} />
        </div>

        <form className={styles.card}>
          <div className={styles.cardTitle}>Orden de compra {orderId}</div>
          <div className={styles.cardText}>
            Para aceptar la orden de compra, ingresa tu kit y contraseña.
          </div>
          <div className={styles.inputContainer}>
            <span className={styles.inputLabel}>Kit:</span>
            <input
              value={form.kit}
              name='kit'
              placeholder='Ej: TE9000000'
              onChange={handleChange}
              className={styles.input}
            />
          </div>
          <div className={styles.inputContainer}>
            <span className={styles.inputLabel}>Contraseña:</span>
            <input
              value={form.password}
              name='password'
              onChange={handleChange}
              className={styles.input}
              type='password'
            />
          </div>
          <Button
            type='submit'
            variant='contained'
            size='large'
            color='primary'
            className={styles.button}
            fullWidth
            onClick={(e) => handleSubmit(e)}>
            {(loading && <CircularProgress className={styles.circularProgress} />) ||
              'Aceptar orden'}
          </Button>
        </form>
      </div>
    </div>
  );
}
