import { useState } from 'react';

// Components
import Form from './views/Form';
import Wellcome from './views/Wellcome';

import styles from './index.module.sass';

export default function ContractUS({ clearSession, history, device, typecontract, getCookie }) {
  const [response, setResponse] = useState({});

  const { accionSiguiente = '', accionSiguienteMensaje = '' } = response;
  return (
    <div className={styles.contractMainContainer}>
      {(accionSiguiente === 'FIN' && accionSiguienteMensaje === 'Mensaje Bienvenida...' && (
        <div>
          <Wellcome recordSuccess={response} history={history} typecontract={typecontract} />
        </div>
      )) || (
        <Form
          setResponse={setResponse}
          clearSession={clearSession}
          device={device}
          typecontract={typecontract}
          history={history}
          getCookie={getCookie}
        />
      )}
    </div>
  );
}
