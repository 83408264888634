import React, { useState } from 'react';

import PostGifts from './PostGifts';

import styles from '../index.module.sass';

const rowWidth = '20%';

export default function Tablerow({ gift, callback = () => {} }) {
  const [open, setOpen] = useState(false);
  const {
    id = '',
    fechaInicio = '',
    horaInicio = '',
    fechaFin = '',
    horaFin = '',
    codigoProducto = '',
    descripcion = '',
    idPromocion = 1,
    estatus = 'C',
  } = gift;

  const fechaInicioComputed = `${fechaInicio.substring(0, 4)}-${fechaInicio.substring(
    4,
    6,
  )}-${fechaInicio.substring(6, 8)}T${horaInicio.substring(0, 2)}:${horaInicio.substring(2, 4)}`;

  const fechaFinComputed = `${fechaFin.substring(0, 4)}-${fechaFin.substring(
    4,
    6,
  )}-${fechaFin.substring(6, 8)}T${horaFin.substring(0, 2)}:${horaFin.substring(2, 4)}`;

  return (
    <>
      <div
        className={styles.giftRow}
        style={{
          borderBottomWidth: open ? 0 : 1,
        }}>
        <div
          className={`${styles.giftColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          {`${fechaInicio.substring(0, 4)}-${fechaInicio.substring(4, 6)}-${fechaInicio.substring(
            6,
            8,
          )}`}
        </div>
        <div
          className={`${styles.giftColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          {`${fechaFin.substring(0, 4)}-${fechaFin.substring(4, 6)}-${fechaFin.substring(6, 8)}`}
        </div>
        <div
          className={`${styles.giftColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          {codigoProducto}
        </div>
        <div
          className={styles.giftColumn}
          style={{
            width: rowWidth,
          }}>
          {descripcion}
        </div>
        <div
          className={`${styles.giftColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          {estatus === 'C' ? 'Cancelado' : 'Vigente'}
        </div>
        <div
          className={`${styles.giftColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          <i
            className='material-icons'
            onClick={() => {
              setOpen(!open);
            }}>
            edit
          </i>
        </div>
      </div>
      {open && (
        <div className={styles.postContainer}>
          <PostGifts
            claveProductoInit={codigoProducto}
            descripcionRegaloInit={descripcion}
            tipoRegaloInit={idPromocion}
            fechaInicioInit={fechaInicioComputed}
            fechaFinInit={fechaFinComputed}
            idInit={id}
            statusInit={estatus}
            showStatus={true}
            callback={callback}
          />
        </div>
      )}
    </>
  );
}
