import { useState } from 'react';

// Components
import InputText from '../../InputText';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../../../Alert';

// Queries
import AvalaraResolveAddress from '../../../ContratosUSA/AvalaraResolveAddress';

// Styles
import styles from '../../index.module.sass';

// Objects
import usStates from '../../usStates';

export default function Address({
  contractFields,
  handleChange,
  setContractFields,
  typecontract,
  notVerifiedChecked,
  setNotVerifiedChecked,
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [alertText, setAlertText] = useState('');

  async function verifyAddress() {
    const { calle = '', ciudad = '', estado = '', cp = '', entreCalle = '' } = contractFields;
    if (!calle || !ciudad || !estado || !cp) {
      setAlertText('Por favor llena los campos obligatorios');
      setOpen(true);
      return;
    }
    setLoading(true);
    const resp = await AvalaraResolveAddress({
      jsonBody: {
        line1: calle,
        line2: entreCalle,
        city: ciudad,
        region: estado,
        postalCode: cp,
        country: 'US',
      },
    });

    const { message = '', tipoMensaje, validatedAddresses = [] } = resp;
    const { addressType = '' } = validatedAddresses[0] || {};

    if (tipoMensaje === 'success' && addressType !== 'POBoxAddress' && validatedAddresses[0]) {
      const { city, line1, line2, region, postalCode } = validatedAddresses[0];

      if (region === 'MT') {
        setContractFields({
          ...contractFields,
          validado: false,
        });
        setAlertText('Por el momento no es posible dar de alta el estado de Montana');
        setOpen(true);
      } else {
        setContractFields({
          ...contractFields,
          validado: true,
          calle: line1,
          entreCalle: line2,
          estado: region,
          ciudad: city,
          cp: postalCode,
        });
      }
    } else if (addressType === 'POBoxAddress') {
      setAlertText('PO BOX addresses are not allowed');
      setOpen(true);
    } else if (tipoMensaje === 'inspect') {
      setAlertText(message);
      setOpen(true);
      setContractFields({
        ...contractFields,
        validado: false,
      });
    } else {
      if (message) setAlertText(message);
      else setAlertText('Validation error');

      setOpen(true);
      setContractFields({
        ...contractFields,
        validado: false,
      });
    }

    setLoading(false);
  }

  return (
    <div className={`${styles.personal}`}>
      <Alert open={open} setOpen={setOpen} title='Terramar' text={alertText} />
      <Alert
        cancel
        open={openWarning}
        setOpen={setOpenWarning}
        title='Atención'
        text='Al no verificar esta dirección, Terramar no se hace responsable si los pedidos no llegan al destino correcto.'
      />
      <div className={styles.areaName}>
        <i className={`material-icons-outlined ${styles.titleIcon}`}>home</i>
        Dirección de facturacción
      </div>
      <div className={styles.areaContainer}>
        <div className={`${styles.inputContainer} ${styles.width100}`}>
          <InputText
            type='text'
            placeholder='Dirección*'
            className={styles.inputText}
            onChange={handleChange}
            name='calle'
            maxLength='40'
            value={contractFields?.calle || ''}
            requiredText='* Campo obligatorio'
            required
          />
        </div>
        {contractFields?.entreCalle && (
          <div className={`${styles.inputContainer} ${styles.width100}`}>
            <InputText
              type='text'
              placeholder='Complemento de dirección'
              className={styles.inputText}
              onChange={handleChange}
              name='entreCalle'
              maxLength='39'
              value={contractFields?.entreCalle || ''}
              disabled
            />
          </div>
        )}

        <div className={`${styles.inputContainer} ${styles.width33}`}>
          <InputText
            type='text'
            placeholder='Ciudad*'
            className={styles.inputText}
            onChange={handleChange}
            name='ciudad'
            maxLength='34'
            value={contractFields?.ciudad || ''}
            requiredText='* Campo obligatorio'
            required
          />
        </div>

        <div className={`${styles.inputContainer} ${styles.width33}`}>
          <select
            className={styles.inputText}
            onChange={(e) => handleChange(e)}
            name='estado'
            maxLength='34'
            value={contractFields?.estado || ''}>
            <option value=''>-- Selecciona un estado -- *</option>
            {usStates.map((val, key) => {
              const { code = '', name = '' } = val;
              return (
                <option value={code} key={key}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>

        <div className={`${styles.inputContainer} ${styles.width33}`}>
          <InputText
            type='text'
            placeholder='Código postal *'
            className={styles.inputText}
            onChange={handleChange}
            name='cp'
            maxLength='34'
            value={contractFields?.cp || ''}
            requiredText='* Campo obligatorio'
            required
          />
        </div>
        <div className={styles.verify}>
          <div className={styles.verifyButton}>
            <Button
              type='submit'
              variant='contained'
              size='large'
              color='primary'
              className={`${styles.button}`}
              onClick={verifyAddress}>
              {(loading && <CircularProgress className={styles.circularProgress} />) || (
                <span className={styles.buttonText}>Validar dirección</span>
              )}
            </Button>
          </div>
          <div className={`${styles.verifyText} ${styles[contractFields?.validado]}`}>
            <i className={`material-icons-outlined ${styles.validateIcon}`}>
              {(contractFields?.validado === true && 'check_circle') || 'cancel'}
            </i>
            {(contractFields?.validado === true && 'La dirección ha sido validada') ||
              'La dirección no ha sido validada'}
          </div>
        </div>
        <div className={styles.checkboxContainer}>
          <Checkbox
            checked={notVerifiedChecked}
            onClick={() => {
              if (!notVerifiedChecked) {
                setOpenWarning(true);
                setNotVerifiedChecked(true);
              } else {
                setNotVerifiedChecked(false);
              }
            }}
          />
          <span>No verificar esta dirección</span>
        </div>
      </div>
    </div>
  );
}
