// Components
import InputText from '../../InputText';

// Styles
import styles from '../../index.module.sass';

export default function Contact({ contractFields, handleChange, typecontract, device }) {
  const { browser = '' } = device || {};
  return (
    <div className={`${styles.personal}`}>
      <div className={styles.areaName}>
        <i className={`material-icons-outlined ${styles.titleIcon}`}>contact_phone</i>
        Contacto
      </div>
      <div className={styles.areaContainer}>
        <div className={`${styles.inputContainer} ${styles.width50}`}>
          <InputText
            type='text'
            placeholder='Celular *'
            className={styles.inputText}
            onChange={handleChange}
            name='celular'
            maxLength='10'
            value={contractFields?.celular || ''}
            requiredText='* Campo obligatorio'
            autoComplete={(browser === 'Chrome' && 'nope') || 'off'}
            required
          />
        </div>
        <div className={`${styles.inputContainer} ${styles.width50}`}>
          <InputText
            type='email'
            placeholder='Email *'
            className={styles.inputText}
            onChange={handleChange}
            name='email'
            maxLength='100'
            value={contractFields?.email || ''}
            requiredText='* Campo obligatorio'
            autoComplete={(browser === 'Chrome' && 'nope') || 'off'}
            errorText={
              contractFields?.email &&
              contractFields?.email !== '' &&
              !contractFields.emailValidation
                ? 'Ingresa una dirección de correo electrónico valida'
                : ''
            }
            error={
              contractFields?.email &&
              contractFields?.email !== '' &&
              !contractFields.emailValidation
                ? true
                : false
            }
            required
          />
        </div>
      </div>
    </div>
  );
}
