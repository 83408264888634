import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.sass';

const country = process.env.REACT_APP_COUNTRY;

const Menu = (props) => {
  const { device, open, setOpen, optionSelected, clearSession, permissions, Lang = {} } = props;

  const { screenSize } = device;
  const { langMenu = {} } = Lang;

  return (
    <>
      {open && (
        <div className={`${styles.overlay} ${styles[open]}`} onClick={() => setOpen(false)} />
      )}
      <div className={`${styles.menuContainer} ${styles[open]} ${styles[screenSize]}`}>
        <div className={styles.header}>Terramar Brands</div>
        <div className={styles.itemsContainer}>
          <div className={styles.title}>Menú</div>
          {permissions.atenciones === '1' && (
            <Link
              to={`/admin/atentions/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'atentions']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>fact_check</i>
              {langMenu.atentions || ''}
            </Link>
          )}
          {permissions.indicadores === '1' && (
            <Link
              to={`/admin/indicators/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'indicators']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>leaderboard</i>
              {langMenu.indicators || ''}
            </Link>
          )}
          {permissions.indicadores === '1' && country === 'US' && (
            <Link
              to={`/admin/digitalcatalog/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'digitalcatalog']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>feed</i>
              {langMenu.digitalcatalog || ''}
            </Link>
          )}
          {permissions.producto === '1' && (
            <Link
              to={`/admin/products/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'products']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>battery_full</i>
              {langMenu.products || ''}
            </Link>
          )}
          {permissions.estafeta === '1' && (
            <Link
              to={`/admin/estafeta/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'estafeta']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>local_shipping</i>
              {langMenu.estafeta || ''}
            </Link>
          )}
          {permissions.dhl === '1' && (
            <Link
              to={`/admin/dhl/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'dhl']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>local_shipping</i>
              {langMenu.dhl || ''}
            </Link>
          )}
          {permissions.dhl === '1' && (
            <Link
              to={`/admin/dhlAvant/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'dhlAvant']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>local_shipping</i>
              {langMenu.dhlAvant || ''}
            </Link>
          )}
          {permissions.paqueteExpress === '1' && (
            <Link
              to={`/admin/paqueteExpress/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'Paquete Express']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>local_shipping</i>
              {langMenu.paquete || ''}
            </Link>
          )}
          {permissions.loadSheet === '1' && (
            <Link
              to={`/admin/loadsheet/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'loadsheet']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>description</i>
              {langMenu.loadSheet || ''}
            </Link>
          )}
          {permissions.rop === '1' && (
            <Link
              to={`/admin/enableROP/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'rop']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>group</i>
              {langMenu.rop || ''}
            </Link>
          )}
          {permissions.rh === '1' && (
            <Link
              to={`/admin/surveyadmin/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'surveyadmin']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>apartment</i>
              {langMenu.surveyadmin || ''}
            </Link>
          )}

          {process.env.REACT_APP_COUNTRY === 'MX' && (
            <Link
              to={`/admin/survey/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'survey']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>mood</i>
              {langMenu.survey || ''}
            </Link>
          )}

          {process.env.REACT_APP_COUNTRY === 'MX' && (
            <Link
              to={`/admin/terrapedia/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'terrapedia']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>book</i>
              {langMenu.terrapedia || ''}
            </Link>
          )}

          {permissions.altaContratos === '1' && (
            <Link
              to={`/admin/contractsUSAWithPermission/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'contratos']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>description</i>
              Alta de contrato
            </Link>
          )}

          {permissions.atenciones === '1' && (
            <Link
              to={`/admin/contratosUSA/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'contratos']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>description</i>
              {langMenu.contratos || ''}
            </Link>
          )}

          {permissions.payQuicker === '1' && (
            <Link
              to={`/admin/payquicker/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'payquicker']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>description</i>
              Payquicker
            </Link>
          )}

          {permissions.payQuickerApi === '1' && (
            <Link
              to={`/admin/payquickerv2/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'payquicker']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>description</i>
              Payquicker V2
            </Link>
          )}

          {permissions.rh === '1' && (
            <Link
              to={`/admin/promos/regalos`}
              className={`${styles.menuItem} ${styles[optionSelected === 'promos']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>fact_check</i>
              Promociones
            </Link>
          )}

          {permissions.notificaciones === '1' && (
            <Link
              to={`/admin/notifications/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'indicators']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>notifications</i>
              Notificationes
            </Link>
          )}

          <div
            className={styles.closeSession}
            onClick={() => {
              clearSession();
            }}>
            <i className='material-icons'>exit_to_app</i>
            {langMenu.logout || ''}
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
