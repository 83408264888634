import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './index.module.sass';
import superagent from 'superagent';
import InputFly from '../../misc/InputFly';

import BG from '../../images/sign/admin.jpg';
import LOGO from '../../images/sign/logo.png';

const Sign = (props) => {
  const { device, webserviceURL, Lang = {} } = props;
  const { langSign = {} } = Lang;
  const screenSize = device.screenSize;
  const [errorsArr, setErrorsArr] = useState([]);
  const [form, setForm] = useState({ employee: '', password: '' });
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const handleChange = (e, type) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setErrorsArr([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorsArr([]);
    const url = `${webserviceURL}/getConsultoriaLogin`;

    let params = {
      usuario: form.employee,
      claveAcceso: form.password,
    };

    const employeeNum = parseInt(form.employee.toUpperCase().replace('TE', '').replace('TM', ''));

    if (employeeNum < 9000000) {
      alert('no tienes acceso a esta sección');
      return;
    }
    setLoading(true);
    superagent
      .post(url)
      .set('Content-Type', 'application/json')
      .set('seccion', 'ATENCIONES')
      .send(params)
      .then((res) => {
        const { body = {} } = res;

        const { tipoMensaje, permisosAdmin, token, nombre } = body;
        if (body && body.tipoMensaje === 'error') {
          setErrorsArr([{ message: langSign.incorrectData || '.' }]);
          setLoading(false);
        } else if (tipoMensaje === 'exito' && permisosAdmin && token) {
          let access = true;

          setLoading(false);
          if (access) {
            document.cookie = 'TMBcartToken=' + token + '; path=/; max-age=3600';
            document.cookie = 'st=' + token + '; path=/; max-age=3600';
            localStorage.setItem('TMBcartToken', token);
            localStorage.setItem('TMBtoken', token);
            localStorage.setItem('TMBadmin', JSON.stringify(permisosAdmin));
            document.cookie = 'admin=' + JSON.stringify(permisosAdmin) + '; path=/; max-age=3600';
            document.cookie = `kitName=${nombre}; path=/; max-age=3600`;
            // history.push(`/admin`);
            navigate('admin');
          } else {
            setErrorsArr([{ message: langSign.permissionDenied }]);
          }
        } else {
          setErrorsArr([{ message: langSign.incorrectInfo }]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={`${styles.container} ${styles[screenSize]}`}>
      <div className={styles.left}>
        <img src={LOGO} alt={langSign.altLogo} className={styles.logo} />
        <form className={styles.formContainer}>
          <h1 className={styles.title}>{langSign.title}</h1>
          <div className={styles.error}>
            {errorsArr.map((error, key) => {
              return <div key={key}>{error.message}</div>;
            })}
          </div>
          <div className={styles.inputContainer}>
            <InputFly
              value={form.employee}
              placeholder={langSign.employeeInput || ''}
              handleChange={handleChange}
              name='employee'
              type='text'
            />
          </div>
          <div className={styles.inputContainer}>
            <InputFly
              value={form.password}
              placeholder={langSign.passInput || ''}
              handleChange={handleChange}
              name='password'
              type='password'
            />
          </div>
          <Button
            type='submit'
            variant='contained'
            size='large'
            color='primary'
            className={styles.button}
            onClick={(e) => handleSubmit(e)}>
            {(loading && <CircularProgress className={styles.circularProgress} />) ||
              langSign.buttonText ||
              ''}
          </Button>
        </form>
      </div>
      {screenSize === 'desktop' && (
        <div
          className={styles.right}
          style={{
            backgroundImage: `url(${BG})`,
          }}
        />
      )}
    </div>
  );
};

export default Sign;
