import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';

import NewNotifications from './NewNotification';
import NotificationsSent from './NotificationsSent';

import styles from './index.module.sass';

export default function Notifications({ device, history, webserviceURL, getCookie, token, match }) {
  return (
    <div className={styles.container}>
      <div className={styles.secondaryMenu}>
        <Link to={`/admin/notifications/`} className={styles.secondaryLink}>
          Crear Notificación
        </Link>
        <Link to={`/admin/notifications/enviadas`} className={styles.secondaryLink}>
          Notificaciones enviadas
        </Link>
      </div>
      <Routes>
        <Route
          exact
          path={`/*`}
          element={
              <NewNotifications
                device={device}
                history={history}
                webserviceURL={webserviceURL}
                getCookie={getCookie}
                token={token}
              />
            }
        />
        <Route
          path={`enviadas`}
          element={
              <NotificationsSent
                device={device}
                history={history}
                webserviceURL={webserviceURL}
                getCookie={getCookie}
                token={token}
              />}
        />
      </Routes>
    </div>
  );
}
