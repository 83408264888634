import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import styles from '../../index.module.sass';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import SendMediation from '../SendMediation';

const StatusPayQuicker = {
  COM: {
    id: 'Completed',
    desc: 'Completado',
    color: '#4C9864',
  },
  PRO: {
    id: 'InProgress',
    desc: 'En progreso',
    color: '#0080C8 ',
  },
  NST: {
    id: 'NotStarted',
    desc: 'Invitación enviada',
    color: '#C25000',
  },
  NRF: {
    id: 'NoResultFound',
    desc: 'Invitación no enviada',
    color: '#CC0000',
  },
  PGP: {
    id: 'ParentGuardianInProgress',
    desc: 'ParentGuardianInProgress',
    color: '',
  },
  PGN: {
    id: 'ParentGuardianNotStarted',
    desc: 'ParentGuardianNotStarted',
    color: '',
  },
  NEV: {
    id: 'MailNotValidated',
    desc: 'Email no validado',
    color: '#CC0000',
  },
};

const columns: GridColDef[] = [
  { field: 'Axo', headerName: 'Año', width: 70 },
  { field: 'FechaGeneracionPago', headerName: 'Fecha', width: 100 },
  { field: 'HoraGeneracionPago', headerName: 'Hora', width: 100 },
  { field: 'MontoPago', headerName: 'Monto', width: 100 },
  { field: 'NoKit', headerName: 'Kit', width: 100 },
  { field: 'Periodo', headerName: 'Periodo', width: 100 },
  { field: 'Status', headerName: 'Estatus', width: 100 },
  {
    field: 'StatusPayQuicker',
    headerName: 'Estatus PayQuicker',
    width: 200,
    renderCell: (params: GridRenderCellParams<Date>) => {
      return (
        <span
          style={{
            color: StatusPayQuicker[params?.row?.StatusPayQuicker]?.color,
          }}>
          {StatusPayQuicker[params?.row?.StatusPayQuicker]?.desc}
        </span>
      );
    },
  },
  { field: 'Comentario', headerName: 'Comentario', width: 400 },
];

export default function MediationDetail({ clearSession }) {
  const { mediationYear, mediationPeriod, tipoTransaccion } = useParams();
  const [loading, setLoading] = useState(false);
  const [mediationDetail, setMediationDetail] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [viewToShow, setViewToShow] = useState('detail');
  const navigate = useNavigate();

  const getDetail = useCallback(async () => {
    setLoading(true);
    await fetch(
      `${process.env.REACT_APP_WEBSERVICE}/getDetallePagosMediacion?axo=${mediationYear}&periodo=${mediationPeriod}&tipoTransaccion=${tipoTransaccion}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
        },
      },
    )
      .then((res) => res.json())
      .then(async (res) => {
        const { typeMessage = 'error', messageCode, message, DataDetallePagosMediacion = [] } = res;
        if (typeMessage === 'success' && messageCode === 200) {
          setMediationDetail(DataDetallePagosMediacion);
        } else {
          if (message === '498' || message === '499') {
            alert('Tu sesión a caducado');
            clearSession();
          }
        }
      })
      .catch((err) => {
        console.log('ERROR', err);
      });
    setLoading(false);
  }, [mediationYear, mediationPeriod, tipoTransaccion, clearSession]);

  const changeView = useCallback(() => {
    if (rowsSelected.length <= 0) {
      alert('No se ha elegido nada para enviar');
      return;
    }
    if (!loading) {
      if (viewToShow === 'detail') {
        setViewToShow('sendMediation');
      }
      if (viewToShow === 'sendMediation') {
        setViewToShow('detail');
        setRowsSelected([]);
        navigate('/admin/payquickerv2/mediation');
      }
    } else {
      return;
    }
  }, [loading, viewToShow, rowsSelected, navigate]);

  useEffect(() => {
    if (viewToShow === 'detail') {
      getDetail();
    }
  }, [getDetail, viewToShow]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              if (!loading) changeView();
            }}
            color='primary'>
            {viewToShow === 'sendMediation' ? 'Regresar' : 'Enviar mediación'}
          </Button>
          {loading && <CircularProgress className={styles.circularProgres} />}
        </div>
      </div>
      <div className={styles.tableContainder}>
        {viewToShow === 'detail' && (
          <DataGrid
            rows={mediationDetail}
            getRowId={(row) => row.IdTransaccion}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            pageSizeOptions={[50, 100]}
            isRowSelectable={(params) => {
              return params?.row?.StatusPayQuicker === 'COM';
            }}
            checkboxSelection
            onRowSelectionModelChange={(ids) => {
              const selectedRowsData = ids.map((id) => {
                return mediationDetail.find((row) => row.IdTransaccion === id);
              });
              setRowsSelected(selectedRowsData);
            }}
          />
        )}
        {viewToShow === 'sendMediation' && (
          <SendMediation
            mediationToSend={rowsSelected}
            clearSession={clearSession}
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
}
