import React, { useEffect, useState, useCallback } from 'react';
import styles from './index.module.sass';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import InputFly from '../../../misc/InputFly';
import SelectType from '../../../misc/SelectType';
import TableRow from './TableRow';

import superagent from 'superagent';

const estatus = [
  {
    label: 'Todos',
    value: 'Todos',
  },
  {
    label: 'Solicitado',
    value: 'Solicitado',
  },
  {
    label: 'Pendiente',
    value: 'Pendiente',
  },
  {
    label: 'Aceptado',
    value: 'Aceptado',
  },
];

const nowDate = new Date();

const primerDesde = `${nowDate.getFullYear()}${('0' + (nowDate.getMonth() + 1)).slice(-2)}01`;
const primerHasta = `${nowDate.getFullYear()}${('0' + (nowDate.getMonth() + 1)).slice(-2)}${(
  '0' + nowDate.getDate()
).slice(-2)}`;

const Detail = (props) => {
  const { webserviceURL, device } = props;
  const { screenSize } = device;
  const [loading, setLoading] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);
  const [serchData, setSearchData] = useState({
    desde: primerDesde,
    hasta: primerHasta,
    estatus: 'Todos',
    kit: '',
  });
  const [digitalOrders, setDigitalOrders] = useState([]);

  const getReporteCatalogoDigital = useCallback(
    async (searchObject) => {
      setLoading(true);
      const url = `${webserviceURL}/getReporteCatalogoDigital?fechaInicio=${searchObject.desde.replace(
        /-/g,
        '',
      )}&fechaFin=${searchObject.hasta.replace(/-/g, '')}&estatus=${searchObject.estatus}&kit=${
        searchObject.kit
      }&idioma=1`;
      await superagent
        .get(url)
        .set('Content-Type', 'application/json')
        .set('token', localStorage.getItem('TMBtoken'))
        .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
        .then((res) => {
          setDigitalOrders(res?.body?.detalle || []);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert('An error occurred');
        });
    },
    [webserviceURL],
  );

  useEffect(() => {
    getReporteCatalogoDigital({
      desde: primerDesde,
      hasta: primerHasta,
      estatus: 'Todos',
      kit: '',
    });
  }, [getReporteCatalogoDigital]);

  const handleChange = useCallback((e) => {
    setSearchData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }, []);

  return (
    <div className={`${styles.container} ${styles[screenSize]}`}>
      <div className={styles.searchContainer}>
        <div className={styles.inputContainer}>
          <InputFly
            handleChange={handleChange}
            name='desde'
            placeholder='De la fecha'
            type='date'
            shrink={true}
            value={serchData.desde}
            maxlength='34'
          />
        </div>
        <div className={styles.inputContainer}>
          <InputFly
            handleChange={handleChange}
            name='hasta'
            placeholder='A la fecha'
            type='date'
            shrink={true}
            value={serchData.hasta}
            maxlength='34'
          />
        </div>
        <div className={styles.inputContainer}>
          <SelectType
            handleChange={(e) => handleChange(e)}
            options={estatus}
            value={serchData.estatus}
            name='estatus'
            placeholder='Estatus'
          />
        </div>
        <div className={styles.inputContainer}>
          <InputFly
            className={styles.algo}
            handleChange={handleChange}
            name='kit'
            placeholder='Kit'
            type='text'
            value={serchData.kit}
            maxlength='34'
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              getReporteCatalogoDigital(serchData);
            }}
            color='primary'
            variant='contained'
            size='large'
            className={styles.button}>
            {loading ? <CircularProgress className={styles.circularProgress} /> : 'Buscar'}
          </Button>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div
          className={styles.table}
          style={{
            width: (screenSize === 'phone' && `${120 * 6}px`) || 'auto',
          }}>
          <TableRow
            order={{
              fecha: 'Fecha (aaa-mm-dd)',
              noKit: 'Kit',
              estatus: 'Estatus',
              productos: 'Productos',
              articulos: 'Artículos',
              total: 'Total',
            }}
            screenSize={screenSize}
            isHeader={true}
          />
          {digitalOrders.map((order, key) => {
            return (
              <div onClick={() => setRowSelected(key)} key={key}>
                <TableRow
                  order={order}
                  screenSize={screenSize}
                  rowSelected={rowSelected}
                  rowNumber={key}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Detail;
