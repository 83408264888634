import React, { useState, useEffect, useCallback } from 'react';
import styles from '../index.module.sass';

//Dependencies
import superagent from 'superagent';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

//Constants
import months from '../months';

const initObject = {
  product: '',
  description: '',
  additDescrip: '',
  productLetter: '',
  baseSubcateg: '',
  promotSubcat: '',
  charactoption: '',
  optInvDown: '',
  discount: '0',
  listPrice: '',
  promotPrice: '0',
  isSet: '',
  category: '',
};

const date = new Date();
const year = date.getFullYear();
const month = ('0' + (date.getMonth() + 1)).slice(-2);

/*
getCatalogo
aaaames                       6A
producto                     15A   
descri                       30A   
descriAdic                   30A   
letraProdAB                   1A   
seccionBase                  15A   
seccionProm                  15A   
caractOpcion                 15A   
claveOpcDesc                 15A   
descuento                     2a   
precioLista                  14a   
precioPromoc                 14a   
setSN                         1a
*/

/*
const stylesUI = {
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    '&$disabled': {
      color: 'white',
    },
    disabled: {},
  },
};
*/

const ProductForm = (props) => {
  const { match, webserviceURL, history, getCookie } = props;
  const [flag, setFlag] = useState(false);
  const [productForm, setProductForm] = useState(initObject);
  const [categories, setCategories] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  const handleChange = (e) => {
    let value = e.target.value;
    const name = e.target.name;
    let baseSubcateg = productForm.baseSubcateg;
    let promotPrice = productForm.promotPrice;

    if (name === 'category') {
      categories.map((val) => {
        if (val.code === value) {
          baseSubcateg = val.subcateg[0].number;
        }
        return null;
      });
    }

    if (name === 'discount') {
      if (parseFloat(value) === 0 || value === '') {
        promotPrice = 0;
        setShowWarning(false);
      } else {
        promotPrice =
          parseFloat(productForm.listPrice) - parseFloat(productForm.listPrice) * parseFloat(value);
        setShowWarning(true);
      }
    }

    if (name === 'promotPrice' && value > 0) setShowWarning(true);
    else if (name === 'promotPrice' && value <= 0) setShowWarning(false);

    setProductForm({
      ...productForm,
      baseSubcateg,
      promotPrice,
      [name]: value,
    });
  };

  const onSubmit = () => {
    //A = para modificar
    //B = para desactivar
    //N = para nuevos registros

    let jsonBody = Object.assign({}, productForm);
    jsonBody.status = (match.params && match.params.productId !== 'newproduct' && 'A') || 'N';
    jsonBody.yearMonth = `${getCookie('yearSelected')}${getCookie('monthSelected')}`;

    delete jsonBody.category;

    const url = `${webserviceURL}/postCatalog`;

    if (jsonBody.product.trim() === '') {
      alert('The product key is missing');
      return;
    }

    superagent
      .post(url)
      .set('Content-Type', 'application/json')
      .auth(
        localStorage.getItem('TMBtoken'),
        (process.env.REACT_APP_COUNTRY === 'MX' && {}) || { type: 'bearer' },
      )
      .send(jsonBody)
      .then((res) => {
        if (res.body) {
          const { body = {} } = res;
          const { message = '' } = body;
          window.scrollTo(0, 0);
          alert(message);
        } else {
          alert('An error occurred, please try again');
        }
      })
      .catch((err) => {
        console.log(err);
        alert('An error occurred');
      });
  };

  const getProduct = useCallback(() => {
    window.scrollTo(0, 0);
    const yearSelected = getCookie('yearSelected') || year;
    const monthSelected = getCookie('monthSelected') || month;

    if (match.params && match.params.productId !== 'newproduct') {
      const url = `${webserviceURL}/getCatalog1?yearMonth=${yearSelected}${monthSelected}&product=${match.params.productId}`;

      superagent
        .get(url)
        .set('Content-Type', 'application/json')
        .auth(
          localStorage.getItem('TMBtoken'),
          (process.env.REACT_APP_COUNTRY === 'MX' && {}) || { type: 'bearer' },
        )
        .then((res) => {
          const { body = {} } = res;
          const { getCatvresult = {}, message = '', typeMessage = 'error' } = body;
          if (typeMessage !== 'error') {
            let tmpObjct = getCatvresult;
            tmpObjct.product = match.params.productId;
            setProductForm(getCatvresult);
          } else {
            alert(message);
            console.log('AN ERROR OCCURRED');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (match.params && match.params.productId === 'newproduct') {
      setProductForm(initObject);
    }
  }, [match, webserviceURL, getCookie]);

  const getCategories = useCallback(() => {
    if (flag) return;
    setFlag(true);
    setLoading(true);
    const url = `${webserviceURL}/getCategorlsg`;
    superagent
      .get(url)
      .auth(
        localStorage.getItem('TMBtoken'),
        (process.env.REACT_APP_COUNTRY === 'MX' && {}) || { type: 'bearer' },
      )
      .then((res) => {
        const { body = {} } = res;
        const { category = [], getCatvresult = {} } = body;
        const { typeMessage = 'error', message = '' } = getCatvresult;
        if (typeMessage !== 'error') {
          setCategories(category, getProduct());
        } else {
          alert(message);
          console.log('ERROR ON LOAD CATEGORIES (LOADSHEET-PRODUCT)');
        }
        setLoading(false);
      })
      .catch((err) => {
        alert('ERROR ON LOAD CATEGORIES WEBSERVICE (LOADSHEET-PRODUCT)');
        console.log(err, 'ERROR ON LOAD CATEGORIES WEBSERVICE (LOADSHEET-PRODUCT)');
        setLoading(false);
      });
  }, [getProduct, flag, webserviceURL]);

  useEffect(() => {
    getCategories();
    setReadOnly(
      parseInt(getCookie('yearSelected')) < year ||
        (parseInt(getCookie('yearSelected')) === year && getCookie('monthSelected') < month),
    );
    //setReadOnly(false)
  }, [getCategories, setReadOnly, getCookie]);

  return (
    <div className={styles.productFormContainer}>
      <div>
        <span className={styles.link} onClick={() => history.goBack()}>
          Regresar
        </span>
      </div>

      <div className={styles.catDate}>
        <span className={styles.text}>Año:</span>
        <span>{getCookie('yearSelected')}</span>
        <span className={styles.text}>Mes:</span>
        <span>{months[parseInt(getCookie('monthSelected')) - 1]}</span>
      </div>
      {(loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )) ||
        (match.params && match.params.productId === 'newproduct' && (
          <div className={styles.inputsContainer}>
            <div className={`${styles.three} ${styles.first}`}>
              <TextField
                onChange={handleChange}
                name='product'
                label='Clave'
                type='text'
                value={productForm.product}
                inputProps={{ maxLength: 15 }}
                variant='outlined'
                fullWidth
                disabled={readOnly}
              />
            </div>
          </div>
        )) || <div className={styles.attributeName}>Clave: {productForm.product}</div>}
      <div className={styles.inputsContainer}>
        <div className={`${styles.four} ${styles.first}`}>
          <TextField
            onChange={handleChange}
            name='optInvDown'
            label='Clave Opcional Descarga Inventario'
            type='text'
            value={productForm.optInvDown}
            inputProps={{ maxLength: 15 }}
            variant='outlined'
            fullWidth
            disabled={readOnly}
          />
        </div>
        <div className={`${styles.four}`}>
          <TextField
            onChange={handleChange}
            name='description'
            label='Descripción'
            type='text'
            value={productForm.description}
            inputProps={{ maxLength: 30 }}
            variant='outlined'
            fullWidth
            disabled={readOnly}
          />
        </div>
        <div className={styles.four}>
          <TextField
            onChange={handleChange}
            name='additDescrip'
            label='Descripción adicional'
            type='text'
            value={productForm.additDescrip}
            inputProps={{ maxLength: 30 }}
            variant='outlined'
            fullWidth
            disabled={readOnly}
          />
        </div>
        <div className={styles.four}>
          <TextField
            onChange={handleChange}
            name='productLetter'
            label='Letra'
            type='text'
            value={productForm.productLetter}
            inputProps={{ maxLength: 1 }}
            variant='outlined'
            fullWidth
            disabled={readOnly}
          />
        </div>
      </div>

      <div className={styles.inputsContainer}>
        <div className={`${styles.three} ${styles.first}`}>
          <FormControl variant='outlined' fullWidth>
            <InputLabel>Es un set</InputLabel>
            <Select
              label='Es un set'
              value={productForm.isSet}
              onChange={handleChange}
              disabled={readOnly}
              name='isSet'>
              <MenuItem value='S'>Si</MenuItem>
              <MenuItem value='N'>No</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={`${styles.three}`}>
          <FormControl variant='outlined' fullWidth>
            <InputLabel>Departamentos</InputLabel>
            <Select
              label='Departamento'
              value={productForm.category}
              onChange={handleChange}
              disabled={readOnly}
              name='category'>
              {categories.map((option) => {
                if (option.code === '6000') return null;
                return (
                  <MenuItem key={option.code} value={option.code}>
                    {option.descr}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className={`${styles.three}`}>
          <FormControl variant='outlined' fullWidth>
            <InputLabel>Sub departamentos</InputLabel>
            <Select
              label='Sub departamento'
              value={productForm.baseSubcateg}
              onChange={handleChange}
              disabled={readOnly}
              name='baseSubcateg'>
              {categories.map((option, key) => {
                const { subcateg } = option;
                let tmpObjct = [];
                if (option.code === productForm.category) {
                  tmpObjct = subcateg.map((val) => {
                    if (val.number.trim() !== '') {
                      return (
                        <MenuItem key={val.number} value={val.number}>
                          {val.descri}
                        </MenuItem>
                      );
                    }
                    return null;
                  });
                }
                return tmpObjct;
              })}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className={styles.inputsContainer}>
        <div className={`${styles.three} ${styles.first}`}>
          <TextField
            onChange={handleChange}
            name='listPrice'
            label='Precio de lista'
            type='number'
            value={
              (productForm.listPrice !== '' && parseFloat(productForm.listPrice)) ||
              productForm.listPrice
            }
            inputProps={{ maxLength: 14 }}
            variant='outlined'
            fullWidth
            disabled={readOnly}
          />
        </div>
        <div className={styles.three}>
          <TextField
            onChange={handleChange}
            name='discount'
            label='% Descuento'
            type='number'
            value={productForm.discount}
            inputProps={{ maxLength: 2 }}
            variant='outlined'
            fullWidth
            disabled={readOnly}
          />
        </div>
        <div className={styles.three}>
          <TextField
            onChange={handleChange}
            name='promotPrice'
            label='Precio con descuento'
            type='number'
            value={
              (productForm.promotPrice !== 'NaN' && parseFloat(productForm.promotPrice)) ||
              productForm.promotPrice
            }
            inputProps={{ maxLength: 14 }}
            variant='outlined'
            fullWidth
            disabled={readOnly}
          />
        </div>
      </div>

      {showWarning && (
        <div className={styles.warning}>
          Si el campo "Precio con descuento" contiene cualquier cantidad mayor a 0, el producto se
          mostrará en la categoría de "Promociones"
        </div>
      )}

      <div className={styles.buttonsContainer}>
        <Button
          type='button'
          variant='contained'
          size='large'
          color='primary'
          className={`${styles.button}`}
          onClick={(e) => {
            //if (!buttonDisabled) onSubmit(e);
            onSubmit(e);
          }}>
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default ProductForm;
