import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FinishPage from './FinishPage';
import AcceptOrder from './AcceptOrder';

export default function PurchaseOrders() {
  return (
    <div>
      <Routes>
        <Route path={`/finishpage`} element={<FinishPage />} />
        <Route path={`/accept/:orderId?`} element={<AcceptOrder />} />
      </Routes>
    </div>
  );
}
