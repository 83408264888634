import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import styles from './index.module.sass';

import Menu from '../Menu';
import Atentions from '../Atentions';
import Indicators from '../Indicators';
import Loadsheet from '../Loadsheet';
import Products from '../Products';
import ProductForm from '../Products/ProductForm';
import CartProductForm from '../Loadsheet/CartProductForm';
import PromoForm from '../Loadsheet/PromoForm';
import Estafeta from '../Estafeta';
import DHL from '../DHL';
import DHLAvant from '../DHLAvant';
import PaqueteExpress from '../PaqueteExpress';
import EnableROP from '../EnableROP';
import SurveyAdmin from '../Survey';
import ContratosUSA from '../ContratosUSA';
import Survey from '../SurveyEmploye/Survey';
import Questions from '../SurveyEmploye/Questions';
import DigitalCatalog from '../DigitalCatalog';
import Payquicker from '../Payquicker';
import PayquickerV2 from '../PayquickerV2';
import Promos from '../Promos';
import Notifications from '../Notifications';
import ContractsUSAWithPermission from '../ContractsUSAWithPermission';

function getCookie(name) {
  var cookieArr = document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

const Admin = (props) => {
  const { device, history, basename, clearSession, webserviceURL, webserviceURL2, Lang } = props;
  const { screenSize } = device;
  const [sectionName, setSectionName] = useState('Administración');
  const [open, setOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState('');
  //const [permissions, setPermissions] = useState({});
  const permissions = JSON.parse(localStorage.getItem('TMBadmin')) || {};
  const [token, setToken] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!localStorage.getItem('TMBadmin') || !localStorage.getItem('TMBtoken')) {
      clearSession();
      return;
    }
    //setPermissions(JSON.parse(localStorage.getItem('TMBadmin')));
    setToken(localStorage.getItem('TMBtoken'));
  }, [clearSession]);

  return (
    <div id={styles.adminContainer} className={styles[screenSize]}>
      <div className={styles.content}>
        <Menu
          device={device}
          history={history}
          open={open}
          setOpen={setOpen}
          optionSelected={optionSelected}
          basename={basename}
          clearSession={clearSession}
          getCookie={getCookie}
          webserviceURL={webserviceURL}
          permissions={permissions}
          token={token}
          Lang={Lang}
        />
        <div className={styles.main}>
          <div className={styles.header}>
            <i className='material-icons' onClick={() => setOpen(true)}>
              menu
            </i>
            <div className={styles.sectionName}>{sectionName}</div>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.card}>
              <Routes>
                <Route
                  path={`/atentions/*`}
                  element={
                    (permissions.atenciones === '1' && (
                      <Atentions
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />
                <Route
                  path={`/indicators/*`}
                  element={
                    (permissions.indicadores === '1' && (
                      <Indicators
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/products/editproduct/:productId?`}
                  element={
                    (permissions.producto === '1' && (
                      <ProductForm
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/products/*`}
                  element={
                    (permissions.producto === '1' && (
                      <Products
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/estafeta/*`}
                  element={
                    (permissions.estafeta === '1' && (
                      <Estafeta
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/dhl/*`}
                  element={
                    (permissions.estafeta === '1' && (
                      <DHL
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        webserviceURL2={webserviceURL2}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/dhlAvant/*`}
                  element={
                    (permissions.estafeta === '1' && (
                      <DHLAvant
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        webserviceURL2={webserviceURL2}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/paqueteExpress/*`}
                  element={
                    (permissions.paqueteExpress === '1' && (
                      <PaqueteExpress
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        webserviceURL2={webserviceURL2}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/loadsheet/editproduct/:productId?`}
                  element={
                    (permissions.loadSheet === '1' && (
                      <CartProductForm
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/loadsheet/editpromotion/:promoSequence/:typeDiscPrize`}
                  element={
                    (permissions.loadSheet === '1' && (
                      <PromoForm
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/loadsheet/*`}
                  element={
                    (permissions.loadSheet === '1' && (
                      <Loadsheet
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/enableROP/*`}
                  element={
                    (permissions.rop === '1' && (
                      <EnableROP
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/surveyadmin/*`}
                  element={
                    (permissions.rh === '1' && (
                      <SurveyAdmin
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/survey/*`}
                  element={
                    <Survey
                      device={device}
                      history={history}
                      setSectionName={setSectionName}
                      setOptionSelected={setOptionSelected}
                      webserviceURL={webserviceURL}
                      getCookie={getCookie}
                      permissions={permissions}
                      token={token}
                      clearSession={clearSession}
                      Lang={Lang}
                    />
                  }
                />

                <Route
                  path={`/questions/*`}
                  element={
                    <Questions
                      device={device}
                      history={history}
                      setSectionName={setSectionName}
                      setOptionSelected={setOptionSelected}
                      webserviceURL={webserviceURL}
                      getCookie={getCookie}
                      permissions={permissions}
                      token={token}
                      clearSession={clearSession}
                      Lang={Lang}
                    />
                  }
                />

                <Route
                  path={`/digitalcatalog/*`}
                  element={
                    (permissions.indicadores === '1' && (
                      <DigitalCatalog
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/contratosUSA/*`}
                  element={
                    (permissions.atenciones === '1' && (
                      <ContratosUSA
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/payquicker/*`}
                  element={
                    (permissions.payQuicker === '1' && (
                      <Payquicker
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/payquickerv2/*`}
                  element={
                    (permissions.payQuickerApi === '1' && (
                      <PayquickerV2
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/promos/*`}
                  element={
                    (permissions.rh === '1' && (
                      <Promos
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/contractsUSAWithPermission/*`}
                  element={
                    (permissions.altaContratos === '1' && (
                      <ContractsUSAWithPermission
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/notifications/*`}
                  element={
                    (permissions.notificaciones === '1' && (
                      <Notifications
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    )) || <Navigate to={`/withoutpermits`} />
                  }
                />

                <Route
                  path={`/withoutpermits`}
                  element={
                    <h3 className={styles.please}>
                      No tienes los permisos necesarios para ingresar a esta opción.
                    </h3>
                  }
                />
                <Route
                  path={`/`}
                  element={<h3 className={styles.please}>Por favor selecciona una opción.</h3>}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
