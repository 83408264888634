import styles from './index.module.sass';

// Components
import OrderCard from '../../../OrderCard';
import Button from '@mui/material/Button';

const Wellcome = ({ recordSuccess = {}, history }) => {
  return (
    <div className={styles.wellcome}>
      <div className={styles.goldTitle}>BIENVENIDA</div>
      <div className={styles.blueSubtitle}>a la familia.</div>
      <div className={styles.titleWelcome}>
        Te damos la bienvenida y nos sentimos orgullosos de que formes parte de este nuevo reto
        profesional, que hoy decidiste compartir con nosotros.
      </div>

      {/* <OrderCard>
        <div className={styles.blackLabel}>
          {t('success1')} {recordSuccess.nombreCompleto}
        </div>
        <div className={styles.textSuccess}>{t('textSuccess')}</div>
        <div className={styles.verify}>
          <div className={styles.validateSMS} onClick={() => history.push('validate')}>
            <span className={`material-icons`}>verified</span>
            <label className={styles.validateText}>{t('validateCode')}</label>
          </div>
        </div>
      </OrderCard> */}

      <OrderCard>
        <div className={styles.numKit}>
          <label className={styles.blackLabel}>
            El número de KIT que se te ha asignado es: {recordSuccess.kit}
          </label>
        </div>
        <br />
        <div className={styles.textSuccess}>
          Para iniciar sesión en el carrito de compras te debes identificar con:
        </div>
        <div className={styles.textSuccess}>
          <label className={styles.labelGold}>KIT:</label>
          <label className={`${styles.blackLabel} ${styles.paddingLeft}`}>
            TM{recordSuccess.kit}
          </label>
          <br />
          {/* <label className={styles.labelGold}>{t('labelPass')}</label>
          <label className={`${styles.blackLabel} ${styles.paddingLeft}`}>
            {recordSuccess.contrasenia}
          </label> */}
        </div>
        <br />
        <div className={styles.finishButton}>
          <Button
            variant='contained'
            color='primary'
            className={styles.buttonFinish}
            onClick={(e) => window.location.reload()}>
            Terminar
          </Button>
        </div>
      </OrderCard>
    </div>
  );
};

export default Wellcome;
