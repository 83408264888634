import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Gifts from './Gifts';

import styles from './index.module.sass';

export default function Promos({ device, history, webserviceURL, getCookie, token, match }) {
  return (
    <div className={styles.container}>
      <Routes>
        <Route
          path={`regalos/*`}
          element={
              <Gifts
                device={device}
                history={history}
                webserviceURL={webserviceURL}
                getCookie={getCookie}
                token={token}
              />
            }
        />
      </Routes>
    </div>
  );
}
