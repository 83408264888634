import useDevice from './hooks/useDevice';
import React from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import './App.css';

import Sign from './components/Sign';
import Admin from './components/Admin';
import PurchaseOrders from './components/PurchaseOrders';

import ES from './components/Languages/ES.js';
import EN from './components/Languages/EN.js';

let Lang = {};
//let webserviceURL = 'https://terramarbrands.mx/wsTerramar/Service1.svc';
const webserviceURL = process.env.REACT_APP_WEBSERVICE;
const webserviceURL2 = 'https://admin.terramarbrands.mx:8083';

//Language
if (window.location.hostname === 'terramarbrands.us') {
  Lang = EN;
} else {
  Lang = ES;
}

function getCookie(name) {
  var cookieArr = document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

function App({ history = null, userAgent = null, basename }) {
  const device = useDevice({ userAgent });
  let navigate = useNavigate();

  const clearSession = () => {
    localStorage.removeItem('TMBtoken');
    localStorage.removeItem('TMBadmin');
    document.cookie = 'kitName=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    navigate('/');
  };

  return (
    <div id='MainContainer'>
      <Routes>
        <Route
          path={`/admin/*`}
          element={
            getCookie('st') ? (
              <Admin
                device={device}
                history={history}
                basename={basename}
                clearSession={clearSession}
                webserviceURL={webserviceURL}
                webserviceURL2={webserviceURL2}
                Lang={Lang}
              />
            ) : (
              <Navigate to={`/`} />
            )
          }
        />
        <Route
          path={`/purchaseorders/*`}
          element={
            <PurchaseOrders
              device={device}
              history={history}
              basename={basename}
              clearSession={clearSession}
              webserviceURL={webserviceURL}
              webserviceURL2={webserviceURL2}
              Lang={Lang}
            />
          }
        />

        <Route
          path={`/*`}
          element={
            <Sign
              device={device}
              history={history}
              basename={basename}
              webserviceURL={webserviceURL}
              Lang={Lang}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
