import React, { useEffect, useState, useCallback } from 'react';

import Tablerow from './Tablerow';

import styles from '../index.module.sass';

const rowWidth = '16.6666%';

export default function GetGifts() {
  const [giftList, setGiftList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getGiftList = useCallback(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_WEBSERVICE}/getPromociones?desde=0&hasta=15`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const { typeMessage, message, listDataPromociones = [] } = json;
        if (typeMessage === 'error') {
          alert(message);
        } else {
          setGiftList(listDataPromociones);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getGiftList();
  }, [getGiftList]);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.giftTable}>
        <div className={styles.rowHeader}>
          <div
            className={`${styles.giftColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Fecha de inicio
          </div>
          <div
            className={`${styles.giftColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Fecha de fin
          </div>
          <div
            className={`${styles.giftColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Código producto
          </div>
          <div
            className={`${styles.giftColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Descripción
          </div>
          <div
            className={`${styles.giftColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Estatus
          </div>
          <div
            className={`${styles.giftColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Acciones
          </div>
        </div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          giftList.map((gift, key) => {
            return <Tablerow gift={gift} key={key} callback={getGiftList} />;
          })
        )}
      </div>
    </div>
  );
}
