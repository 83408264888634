import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import styles from '../index.module.sass';

function Row({ row }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          {row.message && <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {
                open ? <i className='material-icons' >
                expand_less
                </i> : <i className='material-icons' onClick={() => setOpen(true)}>
                expand_more
                </i>
            }
          </IconButton>}
        </TableCell>
        <TableCell >{row.kit}</TableCell>
        <TableCell >{row.invitationStatus}</TableCell>
        <TableCell >{row.registrationStatus}</TableCell>
        <TableCell >{row.message}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Nota
              </Typography>
              <div>{row.message}</div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({invitedResult, setViewToShow}) {
  return (
    <div>
        <div className={styles.header}>
            <Button onClick={() => {setViewToShow('ToInviteList')}} color='primary'>
            Terminar
            </Button>
        </div>
        
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
                <TableCell />
                <TableCell>Kit</TableCell>
                <TableCell>Invitada</TableCell>
                <TableCell>Registro</TableCell>
                <TableCell>Nota</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {invitedResult.map((row) => (
                <Row key={row.kit} row={row} />
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    </div>
    
  );
}