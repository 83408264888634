import React, { useEffect, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import styles from '../index.module.sass';

export default function Mediation() {
  const [paymentsList, setPaymentsList] = useState([]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function Row({ row }) {
    return (
      <React.Fragment>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={styles.rowStyle}>
          <TableCell>
            <NavLink
              to={`/admin/payquickerv2/mediation/subtotals/${row?.Axo}/${row?.Periodo}`}
              className={styles.tableLink}>
              <span>Ver subtotales</span>
            </NavLink>
          </TableCell>
          <TableCell>{row.Axo}</TableCell>
          <TableCell>{row.Periodo}</TableCell>
          <TableCell>{row.FechaGeneracionPagos}</TableCell>
          <TableCell>{row.HoraGeneracionPagos}</TableCell>
          <TableCell>{row.MontoTotal}</TableCell>
          <TableCell>{row.NoLideres}</TableCell>
          <TableCell>{row.NoLideresConTRAplicada}</TableCell>
          <TableCell>{row.NoTransaccionesAplicadas}</TableCell>
          <TableCell>{row.NoTransaccionesGeneradas}</TableCell>
          <TableCell>{row.PorcentajeAvance}</TableCell>
          <TableCell>{row.Status === 'GEN' ? 'Generado' : 'En proceso'}</TableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  const getPagos = useCallback(async () => {
    await fetch(`${process.env.REACT_APP_WEBSERVICE}/getPagosMediacion`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        const { typeMessage = 'error', messageCode, message, DataPagosMediacion = [] } = res;
        if (typeMessage === 'success' && messageCode === 200) {
          setPaymentsList(DataPagosMediacion);
        } else {
          alert(message);
        }
      })
      .catch((err) => {
        console.log('ERROR', err);
      });
  }, []);

  useEffect(() => {
    getPagos();
  }, [getPagos]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Año</TableCell>
              <TableCell>Periodo</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Hora</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>No. Líderes</TableCell>
              <TableCell>Líderes con tx aplicadas</TableCell>
              <TableCell>Tx aplicadas</TableCell>
              <TableCell>Tx Generadas</TableCell>
              <TableCell>Porcentaje</TableCell>
              <TableCell>Estatus</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentsList.map((row) => (
              <Row key={row.ProcesoGeneracion} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
