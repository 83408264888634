import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import styles from '../index.module.sass';

const columns: GridColDef[] = [
  { field: 'noKit', headerName: 'Kit', width: 70 },
  { field: 'nombre', headerName: 'Nombre', width: 300 },
  {
    field: 'fechaAlta',
    headerName: 'Fecha de alta',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.fechaAlta || ''} ${params.row.horaAlta || ''}`,
  },
];

export default function ToInviteList({ setViewToShow, setInvitedResult }) {
  const [toInvite, setToInvite] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const getToInvite = useCallback(async () => {
    await fetch(`${process.env.REACT_APP_WEBSERVICE}/getPendientesInvitacion`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        const { DetallePendientesInvitacion = [], messageCode, typeMessage, message } = res;

        if (messageCode === 200 && typeMessage === 'success') {
          setToInvite(DetallePendientesInvitacion);
        } else {
          alert(message);
        }
      })
      .catch((err) => {
        console.log('ERROR', err);
      });
  }, []);

  let snedInvitation = useCallback(async () => {
    if (rowsSelected.length <= 0) {
      alert('No se ha elegido nada para enviar');
      return;
    }
    setLoading(true);
    await fetch(`${process.env.REACT_APP_WEBSERVICE}/PostInvitacionPayQuickerV1`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
      },
      body: JSON.stringify({
        idioma: '0',
        kits: rowsSelected,
      }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const { results = [], messageCode, typeMessage, message } = res;

        if (messageCode === 200 && typeMessage === 'success') {
          console.log(results);
          setInvitedResult(results);
          setViewToShow('InvitedResult');
        } else {
          alert(message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log('ERROR', err);
        setLoading(false);
      });
  }, [rowsSelected, setInvitedResult, setViewToShow]);

  useEffect(() => {
    getToInvite();
  }, [getToInvite]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              if (!loading) snedInvitation();
            }}
            color='primary'>
            Enviar invitación
          </Button>
          {loading && <CircularProgress className={styles.circularProgres} />}
        </div>
      </div>

      <div className={styles.tableContainder}>
        <DataGrid
          rows={toInvite}
          getRowId={(row) => row.noKit}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          pageSizeOptions={[50, 100]}
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            // const selectedRowsData = ids.map((id) => toInvite.find((row) => row.noKit === id));
            setRowsSelected(ids);
          }}
        />
      </div>
    </div>
  );
}
