import React from 'react';
import styles from './index.module.sass';

import TableRow from './TableRow';

const NoStockProducts = (props) => {
  const { inventory, rowSelected, setRowSelected } = props;
  const tableHeader = ['Clave', 'Nombre ', 'Unidades', 'Status'];

  return (
    <div className={styles.indicatorTable}>
      <div className={styles.tableHeader}>
        {tableHeader.map((val, key) => {
          return (
            <div className={`${styles.headerItem} ${styles.fullwidth}`} key={key}>
              {val}
            </div>
          );
        })}
      </div>
      {inventory.map((val, key) => {
        if (val.disponible) return null;
        const order = [val.codProducto, val.nombreEs, val.unidades, 'Agotado'];
        return (
          <TableRow
            key={key}
            row={key}
            order={order}
            fullwidth={true}
            rowSelected={rowSelected}
            setRowSelected={setRowSelected}
          />
        );
      })}
    </div>
  );
};

export default NoStockProducts;
