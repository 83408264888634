import React, { useEffect, useState, useCallback } from 'react';
import superagent from 'superagent';
import Button from '@mui/material/Button';
import styles from './index.module.sass';
import getGlobal from '../../../Queries/getGlobal';

import Conversation from './components/Conversation';
//import atentionMails from '../Objects/atentionMails.js';

const imgUrl = 'https://terramarbrands.mx/atenciones-tmb/atentionimages/';

const utf8Decode = (utf8String) => {
  if (typeof utf8String !== 'string') throw new TypeError('parameter ‘utf8String’ is not a string');
  // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
  const unicodeString = utf8String
    .replace(
      /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, // 3-byte chars
      function (c) {
        // (note parentheses for precedence)
        var cc =
          ((c.charCodeAt(0) & 0x0f) << 12) |
          ((c.charCodeAt(1) & 0x3f) << 6) |
          (c.charCodeAt(2) & 0x3f);
        return String.fromCharCode(cc);
      },
    )
    .replace(
      /[\u00c0-\u00df][\u0080-\u00bf]/g, // 2-byte chars
      function (c) {
        // (note parentheses for precedence)
        var cc = ((c.charCodeAt(0) & 0x1f) << 6) | (c.charCodeAt(1) & 0x3f);
        return String.fromCharCode(cc);
      },
    );
  return unicodeString;
};

const Chat = (props) => {
  const { screenSize, setOpen, rowSelected, webserviceURL, searchFunction, searchObject, token } =
    props;
  const [initTransition, setInitTransition] = useState(false);
  const [comment, setComment] = useState('');
  const [imagesrc, setImagesrc] = useState('');
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const getPtoducts = useCallback(async () => {
    const resp = await getGlobal({
      tmbservice: 'getDetalleAtencion',
      jsonBody: {
        atencionId: rowSelected?.folio ?? '',
      },
    });

    if (resp?.messageCode === 200) {
      setProducts(resp?.listProductosAtenciones ?? []);
    }
  }, [rowSelected?.folio]);

  useEffect(() => {
    setTimeout(() => {
      setInitTransition(true);
    }, 100);
  }, []);

  const handleClose = (e) => {
    searchFunction(searchObject);
    setInitTransition(false);
    setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  const sendResponse = () => {
    if (!comment) return;
    setLoading(true);

    /*
    let mails = [];

    atentionMails.map((val, key) => {
      if (val.value === parseInt(rowSelected.ATETIPO)) {
        mails = val.mails || [];
      }
      return true;
    });
    */

    const url = `${webserviceURL}/postAtencionesAltaComentario`;

    let imagenBase64 = '';
    if (imagesrc) {
      const imagenTmp = imagesrc.split(',');
      if (imagenTmp.length > 1) imagenBase64 = imagenTmp[1];
    }

    let params = {
      folio: rowSelected.folio,
      comentarios: comment,
      tipo: '1',
      imagenBase64,
    };

    superagent
      .post(url)
      .set('Content-Type', 'application/json')
      .set('token', token)
      .auth(token, { type: 'bearer' })
      .send(params)
      .then((res) => {
        const { body = {} } = res;
        if (body.tipoMensaje !== 'exito') {
          if (body.mensaje) alert(body.mensaje);
          else alert('No se pudo generar el mensaje, intentalo nuevamente.');
        } else {
          setComment('');
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert('El mensaje no pudo generarse, intentalo nuevamente.');
        setLoading(false);
      });
  };

  const closeAtention = () => {
    let r = window.confirm(
      'Si marcas la atención como "cerrada" ya no podrás modificarla en el futuro, ¿Quieres marcarla como cerrada?',
    );
    if (!r) return;

    setLoading(true);
    /*
    let mails = [];

    atentionMails.map((val, key) => {
      if (val.value === parseInt(rowSelected.ATETIPO)) {
        mails = val.mails || [];
      }
      return true;
    });
    */

    const url = `${webserviceURL}/getAtencionesCerrar?folio=${rowSelected.folio}`;

    superagent
      .get(url)
      .set('Content-Type', 'application/json')
      .set('token', token)
      .auth(token, { type: 'bearer' })
      .then((res) => {
        const { body = {} } = res;
        if (body.tipoMensaje !== 'exito') {
          alert('La atención no pudo cerrarse, intentalo nuevamente.');
        }
        setLoading(false);
        handleClose();
        searchFunction(searchObject);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        alert('No se pudo cerrar la atención, intentalo de nuevo');
      });
  };

  useEffect(() => {
    getPtoducts();
  }, [getPtoducts]);

  let splitDate = rowSelected.fechaCaptura.split('-');
  const year = splitDate[0];
  const month = splitDate[1];
  const day = splitDate[2];

  let status = '';

  if (rowSelected.status === '10') status = 'Capturada';
  if (rowSelected.status === '20') status = 'En seguimiento';
  if (rowSelected.status === '40') status = 'Réplica';
  if (rowSelected.status === '80') status = 'Cerrada';

  return (
    <div id={styles.ChatContainer} className={`${styles[initTransition]} ${styles[screenSize]}`}>
      <div className={styles.shadow} onClick={(e) => handleClose(e)}></div>
      <div className={styles.cardModule}>
        <div className={styles.closeContainer}>
          <span className={styles.close} onClick={(e) => handleClose(e)}>
            X
          </span>
        </div>
        <div className={styles.atentionDetails}>
          <div className={styles.folio}>Folio # {rowSelected.folio}</div>

          <div className={styles.item}>
            <div className={styles.title}>Status</div>
            <div className={styles.itemText}>{status}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.title}>Fecha levantada</div>
            <div className={styles.itemText}>{`${day}/${month}/${year}`}</div>
          </div>

          <div className={styles.title}>Pedido</div>
          <div className={styles.itemText}>{rowSelected?.pedido || ''}</div>

          {products.length > 0 && (
            <>
              <div className={styles.title}>Productos</div>
              <div className={`${styles.itemText} ${styles.productList}`}>
                {products.map((product, key) => (
                  <div key={product.codigoProducto}>
                    {product.codigoProducto} {product.nombre},{' '}
                    <strong>Cantidad: {product.unidades}</strong>
                    {key < products.length - 1 && ', '}
                  </div>
                ))}
              </div>
            </>
          )}

          <div className={styles.item}>
            <div className={styles.title}>Area</div>
            <div className={styles.itemText}>{utf8Decode(rowSelected.areaDescri)}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.title}>Nombre</div>
            <div className={styles.itemText}>{utf8Decode(rowSelected.nombre)}</div>
          </div>

          <div className={styles.item}>
            <div className={styles.title}>Kit afectado</div>
            <div className={styles.itemText}>{rowSelected.kitAfectado}</div>
          </div>

          {rowSelected.imagen && (
            <div className={styles.item}>
              <div className={styles.title}>Imagen</div>
              <img
                src={rowSelected.imagen}
                onClick={() => {
                  window.open(rowSelected.imagen);
                }}
                alt=''
              />
            </div>
          )}

          <div className={styles.item}>
            <div className={styles.title}>Seguimiento</div>
          </div>

          <Conversation
            screenSize={screenSize}
            rowSelected={rowSelected}
            imgUrl={imgUrl}
            utf8Decode={utf8Decode}
            webserviceURL={webserviceURL}
            loading={loading}
            setLoading={setLoading}
            token={token}
          />
        </div>
        {status !== 'Cerrada' && (
          <div className={styles.controlPanel}>
            <div className={styles.textareaContainer}>
              <textarea
                className={styles.textareaStyle}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <div className={styles.uploadContainer}>
              <div className={styles.imageViewport}>
                <input
                  id={styles.imagefile}
                  type='file'
                  accept='image/*'
                  name='upload'
                  onChange={(e) => {
                    let files = e.target.files;
                    let reader = new FileReader();
                    reader.onload = function () {
                      setImagesrc(reader.result);
                    };
                    try {
                      reader.readAsDataURL(files[0]);
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                />
                {imagesrc ? (
                  <img
                    src={imagesrc}
                    onError={() => {
                      console.log('error al leer la imagen');
                    }}
                    alt=''
                  />
                ) : (
                  <div className={styles.imageIcon}>
                    <i className='material-icons'>add_a_photo</i>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <Button
                type='submit'
                variant='contained'
                size='large'
                color='primary'
                className={styles.button}
                onClick={(e) => {
                  if (loading) return;
                  sendResponse();
                }}>
                ENVIAR
              </Button>
              <Button
                type='submit'
                variant='contained'
                size='large'
                color='primary'
                className={`${styles.button} ${styles.closeAtention}`}
                onClick={(e) => {
                  closeAtention();
                }}>
                ATEN. CERRADA
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
